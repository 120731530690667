import { Grid } from "@mui/material";

import useDining from "./dining-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { FullScreenLoader, ServiceCard } from "../../components";

const DiningMenu = () => {
  const { dining, loading } = useDining();

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Dining" />
      <Grid container spacing={2}
        sx={{
          px: 2,
          py: 0,
          flex: 1,
          mt: "0px",
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
          // ...BIG_TILE_RESPONSIVE
        }}
      >
        {Array.isArray(dining) &&
          dining?.find((service) => service.name === "Restaurants")?.sub_services?.map((hotelService: any) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <ServiceCard
                  showInfo={true}
                  key={hotelService?.name}
                  label={hotelService?.name}
                  description={hotelService?.desc}
                  image={hotelService?.img as string}
                  serviceID={hotelService?.service_id}
                  notVerified={true}
                />
              </Grid>
            );
          })}
      </Grid>
    </Layout>
  );
};

export default DiningMenu;
