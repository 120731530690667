import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function FeedbackReviewIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path d="M24.6211 27.0961H57.9138C71.5116 27.0961 82.5349 38.1192 82.5349 51.7172V69.1816C82.5349 82.7793 71.5118 93.8027 57.9138 93.8027H38.805C38.4659 93.8027 38.1598 93.9897 37.9894 94.2832C35.2383 99.0215 29.512 102.398 21.9912 104.341C21.0612 104.582 20.437 103.424 21.1317 102.761C23.9412 100.079 25.3212 97.429 25.553 94.834C25.6024 94.2818 25.1754 93.8027 24.6209 93.8027C11.0231 93.8025 0 82.7793 0 69.1814V51.7172C0 38.1192 11.0231 27.0961 24.6211 27.0961Z" fill="url(#paint0_linear_9052_11787)" />
                <path d="M0 52.1212V69.1814C0 82.7791 11.0231 93.8025 24.6211 93.8025C25.1756 93.8025 25.6024 94.2815 25.5532 94.8337C25.3214 97.4285 23.9414 100.079 21.132 102.761C20.4373 103.424 21.0614 104.582 21.9914 104.341C29.512 102.397 35.2385 99.0213 37.9896 94.2829C38.1598 93.9897 38.4661 93.8025 38.8052 93.8025H57.9141C71.5118 93.8025 82.5351 82.7793 82.5351 69.1814V52.1212H0Z" fill="url(#paint1_linear_9052_11787)" />
                <path d="M57.9136 27.0961H37.582V94.9284C37.7217 94.7151 37.8621 94.5021 37.9891 94.2829C38.1593 93.9897 38.4656 93.8025 38.8048 93.8025H57.9136C71.5113 93.8025 82.5347 82.7793 82.5347 69.1814V51.7169C82.5347 38.1192 71.5116 27.0961 57.9136 27.0961Z" fill="url(#paint2_linear_9052_11787)" />
                <path d="M25.853 59.0416L60.481 93.6696C72.8722 92.3857 82.5347 81.9122 82.5347 69.1814V59.0416H25.853Z" fill="url(#paint3_linear_9052_11787)" />
                <path d="M87.2592 17.8892H47.7408C31.6002 17.8892 18.5156 26.9159 18.5156 38.0509V52.3522C18.5156 63.4872 31.6002 72.5139 47.7408 72.5139H70.4231C70.8255 72.5139 71.1891 72.6672 71.3911 72.9073C74.6564 76.7876 81.4537 79.5523 90.3808 81.1439C91.4845 81.3408 92.2256 80.3926 91.401 79.8496C88.0661 77.6533 86.4281 75.4835 86.1528 73.3585C86.0941 72.9062 86.601 72.5141 87.2592 72.5141C103.4 72.5139 116.484 63.4872 116.484 52.3522V38.0509C116.484 26.9159 103.4 17.8892 87.2592 17.8892Z" fill="url(#paint4_linear_9052_11787)" />
                <path d="M87.3281 17.8892H61.1719V72.5141H70.5315C70.933 72.5141 71.2957 72.6674 71.4972 72.9075C74.7548 76.7878 81.5361 79.5525 90.4421 81.1439C91.5433 81.3408 92.2826 80.3926 91.4599 79.8496C88.1329 77.6533 86.4988 75.4834 86.2241 73.3585C86.1655 72.9062 86.6712 72.5141 87.3279 72.5141C103.431 72.5141 116.484 63.4873 116.484 52.3522V38.051C116.484 26.9159 103.431 17.8892 87.3281 17.8892Z" fill="url(#paint5_linear_9052_11787)" />
                <path d="M120 15.7031V37.061C120 54.0844 106.2 67.8846 89.1763 67.8846C88.4821 67.8846 87.9477 68.4844 88.0093 69.1758C88.2997 72.4245 90.0273 75.7418 93.5446 79.0995C94.4143 79.9298 93.6327 81.3792 92.4686 81.0783C83.0532 78.645 75.8842 74.4183 72.4403 68.486C72.2272 68.1188 71.8438 67.8846 71.4193 67.8846H47.4964C30.4731 67.8846 16.6729 54.0844 16.6729 37.061V15.7031H120Z" fill="url(#paint6_linear_9052_11787)" />
                <path d="M82.5348 51.7172C82.5348 38.1194 71.5117 27.0961 57.9137 27.0961H24.621C21.8396 27.0961 19.1664 27.5578 16.6729 28.4079V37.061C16.6729 54.0843 30.4731 67.8846 47.4964 67.8846H71.4193C71.8438 67.8846 72.2272 68.119 72.4403 68.486C74.3483 71.7726 77.4015 74.5345 81.3447 76.7592C82.1162 74.3718 82.5348 71.8256 82.5348 69.1814V51.7172Z" fill="url(#paint7_linear_9052_11787)" />
                <path d="M103.423 35.647H33.25C32.279 35.647 31.4922 36.434 31.4922 37.4048C31.4922 38.3756 32.279 39.1626 33.25 39.1626H103.423C104.394 39.1626 105.181 38.3756 105.181 37.4048C105.181 36.434 104.394 35.647 103.423 35.647Z" fill="url(#paint8_linear_9052_11787)" />
                <path d="M76.607 48.1781H33.25C32.279 48.1781 31.4922 48.9651 31.4922 49.9359C31.4922 50.9067 32.279 51.6937 33.25 51.6937H76.607C77.578 51.6937 78.3648 50.9067 78.3648 49.9359C78.3648 48.9651 77.578 48.1781 76.607 48.1781Z" fill="url(#paint9_linear_9052_11787)" />

            </g>
            <defs>
                <linearGradient id="paint0_linear_9052_11787" x1="26.6257" y1="31.1655" x2="52.9331" y2="83.7806" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C9F6B0" />
                    <stop offset="1" stop-color="#0BB494" />
                </linearGradient>
                <linearGradient id="paint1_linear_9052_11787" x1="41.2676" y1="75.3932" x2="41.2676" y2="96.3569" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00A887" stop-opacity="0" />
                    <stop offset="0.3602" stop-color="#01A58C" stop-opacity="0.36" />
                    <stop offset="0.7945" stop-color="#069C9A" stop-opacity="0.795" />
                    <stop offset="1" stop-color="#0996A4" />
                </linearGradient>
                <linearGradient id="paint2_linear_9052_11787" x1="66.6722" y1="61.0122" x2="82.6966" y2="61.0122" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00A887" stop-opacity="0" />
                    <stop offset="0.3602" stop-color="#01A58C" stop-opacity="0.36" />
                    <stop offset="0.7945" stop-color="#069C9A" stop-opacity="0.795" />
                    <stop offset="1" stop-color="#0996A4" />
                </linearGradient>
                <linearGradient id="paint3_linear_9052_11787" x1="53.438" y1="90.3722" x2="54.2461" y2="62.4895" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00A887" stop-opacity="0" />
                    <stop offset="0.3602" stop-color="#01A58C" stop-opacity="0.36" />
                    <stop offset="0.7945" stop-color="#069C9A" stop-opacity="0.795" />
                    <stop offset="1" stop-color="#0996A4" />
                </linearGradient>
                <linearGradient id="paint4_linear_9052_11787" x1="54.325" y1="24.6459" x2="70.405" y2="77.3593" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F5FBFF" />
                    <stop offset="1" stop-color="#D8ECFE" />
                </linearGradient>
                <linearGradient id="paint5_linear_9052_11787" x1="97.2054" y1="49.5299" x2="116.182" y2="49.5299" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.4315" stop-color="#B1D9FE" stop-opacity="0.432" />
                    <stop offset="0.6657" stop-color="#A9D6FE" stop-opacity="0.666" />
                    <stop offset="0.852" stop-color="#9BD0FE" stop-opacity="0.852" />
                    <stop offset="1" stop-color="#8AC9FE" />
                </linearGradient>
                <linearGradient id="paint6_linear_9052_11787" x1="68.3364" y1="44.8378" x2="68.3364" y2="71.0824" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.4315" stop-color="#B1D9FE" stop-opacity="0.432" />
                    <stop offset="0.6657" stop-color="#A9D6FE" stop-opacity="0.666" />
                    <stop offset="0.852" stop-color="#9BD0FE" stop-opacity="0.852" />
                    <stop offset="1" stop-color="#8AC9FE" />
                </linearGradient>
                <linearGradient id="paint7_linear_9052_11787" x1="25.9166" y1="9.4481" x2="69.2736" y2="75.1101" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B3DAFE" stop-opacity="0" />
                    <stop offset="0.4315" stop-color="#B1D9FE" stop-opacity="0.432" />
                    <stop offset="0.6657" stop-color="#A9D6FE" stop-opacity="0.666" />
                    <stop offset="0.852" stop-color="#9BD0FE" stop-opacity="0.852" />
                    <stop offset="1" stop-color="#8AC9FE" />
                </linearGradient>
                <linearGradient id="paint8_linear_9052_11787" x1="45.8111" y1="0.882832" x2="82.1767" y2="59.8446" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
                <linearGradient id="paint9_linear_9052_11787" x1="22.6525" y1="-2.3951" x2="69.2148" y2="73.0992" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5A5A5A" />
                    <stop offset="1" stop-color="#444444" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

export { FeedbackReviewIcon };
