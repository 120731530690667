import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MakiraIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="65"
      height="21"
      viewBox="0 0 65 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{width:"65px",marginLeft:"-15px"}}
    >
      <g clipPath="url(#clip0_933_1411)">
        <path
          d="M3.04273 13.4264H0.387695V0.5H3.39157L7.24816 7.79005L11.1241 0.5H14.128V13.4264H11.473V5.36434L7.24816 12.312L3.04273 5.36434V13.4264Z"
          fill="#2D2E2F"
        />
        <path
          d="M23.5659 4.45349H26.0271V13.4264H23.5659V12.186C22.7326 13.2519 21.7636 13.7364 20.4457 13.7364C17.9263 13.7364 16.124 11.7791 16.124 9.04651C16.124 6.31395 18.0232 4.14341 20.6395 4.14341C21.8605 4.14341 22.6938 4.56977 23.5659 5.5969V4.45349ZM18.7597 9.00775C18.7597 10.5775 19.7868 11.7597 21.124 11.7597C22.4612 11.7597 23.6046 10.4806 23.6046 8.93023C23.6046 7.37985 22.5969 6.21705 21.1434 6.21705C19.6899 6.21705 18.7597 7.36047 18.7597 9.00775Z"
          fill="#2D2E2F"
        />
        <path
          d="M31.24 13.4264H28.585V0.5H31.24V6.12015L34.9028 0.5H37.9842L33.7981 6.72093L37.9842 13.4264H34.864L31.24 7.67054V13.4264Z"
          fill="#2D2E2F"
        />
        <path
          d="M41.9191 1.91473C41.9191 2.67054 41.3377 3.25194 40.5625 3.25194C39.7873 3.25194 39.1865 2.67054 39.1865 1.89535C39.1865 1.12016 39.7679 0.51938 40.5625 0.51938C41.3571 0.51938 41.9191 1.10078 41.9191 1.91473ZM41.7834 13.4264H39.3222V4.45349H41.7834V13.4264Z"
          fill="#2D2E2F"
        />
        <path
          d="M47.0154 13.4264H44.3604V0.5H48.3526C51.5503 0.5 53.2751 1.91473 53.2751 4.56977C53.2751 6.60465 52.2092 7.96124 50.2131 8.46512L53.779 13.4264H50.5619L47.0154 8.2907V13.4264ZM48.2169 6.74031C49.7286 6.74031 50.5813 6.00388 50.5813 4.70543C50.5813 3.40698 49.7673 2.68992 48.4495 2.68992H47.0154V6.74031H48.2169Z"
          fill="#2D2E2F"
        />
        <path
          d="M62.1508 4.45349H64.6121V13.4264H62.1508V12.186C61.3175 13.2519 60.3485 13.7364 59.0307 13.7364C56.5113 13.7364 54.709 11.7791 54.709 9.04651C54.709 6.31395 56.6082 4.14341 59.2245 4.14341C60.4454 4.14341 61.2788 4.56977 62.1508 5.5969V4.45349ZM57.3446 9.00775C57.3446 10.5775 58.3718 11.7597 59.709 11.7597C61.0462 11.7597 62.1896 10.4806 62.1896 8.93023C62.1896 7.37985 61.1819 6.21705 59.7284 6.21705C58.2749 6.21705 57.3446 7.36047 57.3446 9.00775Z"
          fill="#2D2E2F"
        />
        <path
          d="M14.1765 20.4483H13.7695V17.4315H14.6481C15.3328 17.4315 15.7495 17.761 15.7495 18.3101C15.7495 18.6492 15.5848 18.9205 15.2844 19.0691C15.1294 19.1466 14.984 19.1789 14.6901 19.1951L15.9401 20.4483H15.4233L14.1733 19.1822V20.4483H14.1765ZM14.5835 18.9238C15.0712 18.9238 15.3361 18.7171 15.3361 18.3359C15.3361 17.9548 15.0938 17.7578 14.6513 17.7578H14.1765V18.9238H14.5835Z"
          fill="#2D2E2F"
        />
        <path
          d="M18.9922 17.8966C19.2539 18.1809 19.3702 18.5168 19.3702 18.969C19.3702 19.4212 19.1764 19.8605 18.8243 20.1415C18.5336 20.3773 18.1686 20.5032 17.7939 20.5032C17.3159 20.5032 16.8475 20.2901 16.5472 19.938C16.3243 19.6764 16.208 19.3372 16.208 18.9561C16.208 18.0549 16.8895 17.3766 17.8036 17.3766C18.2526 17.3766 18.6822 17.5672 18.9922 17.8966ZM16.6247 18.9432C16.6247 19.6441 17.1189 20.1544 17.7842 20.1544C18.1072 20.1544 18.427 20.022 18.6434 19.7926C18.8307 19.5988 18.9535 19.2726 18.9535 18.9658C18.9535 18.562 18.8372 18.2681 18.5853 18.042C18.3721 17.8482 18.0782 17.7287 17.7939 17.7287C17.1221 17.7287 16.6247 18.2455 16.6247 18.9399V18.9432Z"
          fill="#2D2E2F"
        />
        <path
          d="M21.5212 20.1027V20.4451H19.9805V17.4283H20.3874V20.1027H21.5244H21.5212Z"
          fill="#2D2E2F"
        />
        <path
          d="M23.5817 20.1027V20.4451H22.041V17.4283H22.448V20.1027H23.5849H23.5817Z"
          fill="#2D2E2F"
        />
        <path
          d="M24.2736 19.6376C24.293 19.98 24.49 20.1609 24.8421 20.1609C25.1942 20.1609 25.459 19.9541 25.459 19.6505C25.459 19.3889 25.3137 19.2662 24.8066 19.1079C24.4254 18.9851 24.2995 18.927 24.1541 18.8043C23.9894 18.6654 23.9022 18.4651 23.9022 18.2358C23.9022 17.7255 24.293 17.3766 24.8647 17.3766C25.4364 17.3766 25.8079 17.7158 25.8208 18.2422H25.4138C25.4106 18.0872 25.388 18.0162 25.3363 17.9289C25.2459 17.7965 25.065 17.7158 24.8583 17.7158C24.5385 17.7158 24.3124 17.9193 24.3124 18.2099C24.3124 18.3553 24.3802 18.4813 24.4965 18.5588C24.5966 18.6266 24.6838 18.6621 25.0133 18.772C25.3557 18.885 25.5204 18.9658 25.6496 19.0982C25.7885 19.2371 25.866 19.4276 25.866 19.6311C25.866 20.1512 25.4558 20.5032 24.8518 20.5032C24.4416 20.5032 24.1606 20.3676 23.9926 20.0963C23.9151 19.9735 23.8828 19.8475 23.8828 19.7022V19.6376H24.2704H24.2736Z"
          fill="#2D2E2F"
        />
        <path
          d="M28.2558 19.7506L27.9619 20.4483H27.542L28.7532 17.4315H29.2377L30.4231 20.4483H29.9677L29.7028 19.7506H28.259H28.2558ZM28.9858 17.9096L28.3753 19.4244H29.5736L28.9858 17.9096Z"
          fill="#2D2E2F"
        />
        <path
          d="M31.2498 20.4483H30.8428V17.4315H31.2142L32.9326 19.7022V17.4315H33.3395V20.4483H32.981L31.2465 18.1421V20.4483H31.2498Z"
          fill="#2D2E2F"
        />
        <path
          d="M34.1406 17.4283H34.9966C35.9688 17.4283 36.5534 17.9806 36.5534 18.8882C36.5534 19.3986 36.3758 19.8217 36.0463 20.0963C35.7653 20.332 35.3939 20.4451 34.9255 20.4451H34.1439V17.4283H34.1406ZM34.5476 20.1189H34.693C35.2388 20.1189 35.4714 20.064 35.7039 19.8928C35.9946 19.6764 36.14 19.3404 36.14 18.8915C36.14 18.1615 35.7233 17.7481 34.9869 17.7481H34.5508V20.1189H34.5476Z"
          fill="#2D2E2F"
        />
        <path
          d="M39.519 17.4283C40.1746 17.4283 40.5493 17.719 40.5493 18.2293C40.5493 18.4231 40.4879 18.5943 40.3652 18.7235C40.2877 18.8107 40.2102 18.8559 40.0357 18.9173C40.2134 18.9528 40.3006 18.9851 40.404 19.0562C40.5784 19.1886 40.6688 19.3921 40.6688 19.6344C40.6688 19.9154 40.5396 20.1576 40.32 20.2901C40.1326 20.4031 39.9227 20.4451 39.519 20.4451H38.6113V17.4283H39.519ZM39.0151 18.7849H39.4253C39.6805 18.7817 39.8355 18.7494 39.9518 18.6621C40.0777 18.5685 40.1488 18.4134 40.1488 18.2455C40.1488 18.0517 40.0487 17.8805 39.8936 17.8127C39.7773 17.7578 39.6417 17.7416 39.3478 17.7416H39.0151V18.7881V18.7849ZM39.0151 20.1382H39.4221C39.7612 20.1382 39.8904 20.1156 40.0325 20.0381C40.1876 19.9574 40.2651 19.812 40.2651 19.6118C40.2651 19.25 40.039 19.0917 39.5254 19.0917H39.0183V20.1382H39.0151Z"
          fill="#2D2E2F"
        />
        <path
          d="M43.8731 17.8966C44.1347 18.1809 44.251 18.5168 44.251 18.969C44.251 19.4212 44.0572 19.8605 43.7051 20.1415C43.4144 20.3773 43.0495 20.5032 42.6748 20.5032C42.1967 20.5032 41.7284 20.2901 41.428 19.938C41.2051 19.6764 41.0889 19.3372 41.0889 18.9561C41.0889 18.0549 41.7704 17.3766 42.6845 17.3766C43.1334 17.3766 43.563 17.5672 43.8731 17.8966ZM41.5055 18.9432C41.5055 19.6441 41.9997 20.1544 42.6651 20.1544C42.9881 20.1544 43.3079 20.022 43.5243 19.7926C43.7116 19.5988 43.8343 19.2726 43.8343 18.9658C43.8343 18.562 43.7181 18.2681 43.4661 18.042C43.2529 17.8482 42.959 17.7287 42.6748 17.7287C42.0029 17.7287 41.5055 18.2455 41.5055 18.9399V18.9432Z"
          fill="#2D2E2F"
        />
        <path
          d="M48.1264 17.4283H48.5463L47.5612 20.4451H47.2027L46.4921 18.0743L45.7782 20.4451H45.4197L44.4346 17.4283H44.8642L45.5974 19.741L46.3112 17.4283H46.6923L47.3835 19.7506L48.1232 17.4283H48.1264Z"
          fill="#2D2E2F"
        />
        <path
          d="M50.5104 20.1027V20.4451H48.9697V17.4283H49.3767V20.1027H50.5137H50.5104Z"
          fill="#2D2E2F"
        />
        <path
          d="M51.1984 19.6376C51.2178 19.98 51.4149 20.1609 51.7669 20.1609C52.119 20.1609 52.3838 19.9541 52.3838 19.6505C52.3838 19.3889 52.2385 19.2662 51.7314 19.1079C51.3503 18.9851 51.2243 18.927 51.0789 18.8043C50.9142 18.6654 50.827 18.4651 50.827 18.2358C50.827 17.7255 51.2178 17.3766 51.7895 17.3766C52.3612 17.3766 52.7327 17.7158 52.7456 18.2422H52.3386C52.3354 18.0872 52.3128 18.0162 52.2611 17.9289C52.1707 17.7965 51.9898 17.7158 51.7831 17.7158C51.4633 17.7158 51.2372 17.9193 51.2372 18.2099C51.2372 18.3553 51.305 18.4813 51.4213 18.5588C51.5214 18.6266 51.6087 18.6621 51.9381 18.772C52.2805 18.885 52.4452 18.9658 52.5744 19.0982C52.7133 19.2371 52.7908 19.4276 52.7908 19.6311C52.7908 20.1512 52.3806 20.5032 51.7766 20.5032C51.3664 20.5032 51.0854 20.3676 50.9174 20.0963C50.8399 19.9735 50.8076 19.8475 50.8076 19.7022V19.6376H51.1952H51.1984Z"
          fill="#2D2E2F"
        />
        <path
          d="M26.0273 3.15504V0.5L16.1921 0.5V3.15504L26.0273 3.15504Z"
          fill="#2D2E2F"
        />
        <path
          d="M64.6123 3.15504V0.5L54.777 0.5V3.15504L64.6123 3.15504Z"
          fill="#2D2E2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_933_1411">
          <rect
            width="64.2248"
            height="20"
            fill="white"
            transform="translate(0.387695 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { MakiraIcon };
