import { Box, SxProps, Theme, Typography, Stack } from '@mui/material';

import { CardPaper } from './CardPaper';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../store';
import { useGetItineraryQuery } from '../../../generated/graphql';

interface ItinerariesProps {
  sx?: SxProps<Theme>;
}

function Itineraries({ sx }: ItinerariesProps) {
  const guest = useAuth((state: any) => state.guest);
  const propertyID = guest?.property_id;
  const { data: itineraries } = useGetItineraryQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      propertyID: guest?.property_id,
      guestID: guest?.guest_id,
      roomID: guest?.room
    },
    skip: !guest?.guest_id
  });
  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 1, ...(sx ? sx : {}) }}>
      <Link to="itineraries" style={{ textDecoration: 'none', flex: 1 }}>
        <CardPaper sx={{ mr: 0.5, px: 3, py: 2.5 }}>
          <Stack direction="row" justifyContent={'center'}>
            <Typography
              align="center"
              variant="body2"
              sx={{ fontWeight: 600, color: 'primary.main' }}
            >
              {propertyID === 'VKGUyyDp2EzS2eZILbH4x'
                ? 'My Dinacharya'
                : 'View Itineraries'}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              ml={1}
              sx={{ fontWeight: 600, color: 'primary.main' }}
            >
              ({itineraries?.getItinerary?.length || 0})
            </Typography>
          </Stack>
        </CardPaper>
      </Link>
    </Box>
  );
}

export { Itineraries };
