import {
  Box,
  Typography,
  SwipeableDrawer,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { DineInItem } from '../../generated/graphql';
import { Close } from '@mui/icons-material';
import { CHILLI_ICONS } from '../../constant/services';
import { imageFallback } from '../../utils';

interface ExtraItemDrawerProps {
  open: boolean;
  item: DineInItem | null;
  toggleDrawer: () => void;
  ALLERGEN_NAMES: any;
  currency: string | null | undefined;
}
function ItemDrawer({
  open,
  item,
  toggleDrawer,
  ALLERGEN_NAMES,
  currency
}: ExtraItemDrawerProps) {
  const onCloseDialog = () => {
    toggleDrawer();
  };

  if (!item) return <></>;

  const {
    desc,
    img,
    price,
    isVegan,
    name,
    allergens,
    is_recommended,
    spice_level,
    extraOptions,
    selections
  } = item;
  const chili = (CHILLI_ICONS as any)[spice_level || ''];
  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={toggleDrawer}
      onClose={onCloseDialog}
    >
      <Box
        sx={{
          overflow: 'auto',
          maxHeight: '100vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'space-between'}
          color={'#fff'}
          p={2}
          bgcolor={theme => theme.palette.primary.main}
        >
          <Typography variant="h6">{name}</Typography>
          <IconButton color={'inherit'} onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>

        {img ? (
          <Box style={{ padding: '10px' }}>
            <img
              alt=""
              src={img}
              style={{
                objectFit: 'contain',
                borderRadius: '10px',
                width: '100%',
                height: '220px'
              }}
            />
          </Box>
        ) : (
          ''
        )}

        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex' }}>
            {/* <Typography
            variant="body2"
            sx={{ fontWeight: 600, color: 'text.tile' }}
          >
            {name}
          </Typography> */}
            {is_recommended === true && (
              <Tooltip enterTouchDelay={0} title="Chef's Recommendation">
                <Box
                  component="img"
                  onError={imageFallback}
                  style={{
                    height: '20px',
                    width: '20x',
                    marginLeft: '5px'
                  }}
                  src={`/assets/chef.svg`}
                />
              </Tooltip>
            )}
          </Box>
          <Typography variant="body2" sx={{ mt: 0.5, color: 'text.tile' }}>
            {currency} {price}
          </Typography>
          {isVegan === 'Vegan' && (
            <Typography
              sx={{
                color: 'green'
              }}
            >
              Vegan
            </Typography>
          )}
          {isVegan === 'NonVegan' && (
            <Typography
              sx={{
                color: 'red'
              }}
            >
              NonVegan
            </Typography>
          )}
          {allergens?.length !== 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography>Allergens :&nbsp;</Typography>
              {allergens?.map((all, index) => {
                const allergenName = (ALLERGEN_NAMES as any)[all || ''];
                return (
                  <Tooltip
                    enterTouchDelay={0}
                    title={allergenName || ''}
                    key={index}
                  >
                    {index === allergens?.length - 1 ? (
                      <Typography sx={{ pr: 1 }}>{allergenName}</Typography>
                    ) : (
                      <Typography sx={{ pr: 1 }}>{allergenName},</Typography>
                    )}
                  </Tooltip>
                );
              })}
            </Box>
          )}
          <Typography>{chili}</Typography>
          <Typography variant="body2" sx={{ mt: 0.5, color: 'text.tile' }}>
            {desc}
          </Typography>
          {selections && (
            <Typography
              fontSize={17}
              fontWeight={800}
              sx={{ color: 'text.tile' }}
            >
              Selections
            </Typography>
          )}
          {selections &&
            selections.map((selection, index) => (
              <Box
                key={selection?.id}
                // sx={{
                //   mb: 1,
                //   display: 'flex',
                //   alignItems: 'center',
                //   textTransform: 'capitalize',
                //   justifyContent: 'space-between'
                // }}
              >
                <Typography sx={{ color: 'text.tile' }}>
                  {selection?.name}
                </Typography>
                {selection?.min_limit !== 0 && (
                  <Typography
                    fontSize={12}
                    color={'#808080'}
                    fontFamily={'cursive'}
                    fontWeight={600}
                  >
                    (Please choose {selection?.min_limit || 0} -{' '}
                    {selection?.limit})
                  </Typography>
                )}
                {selection?.selection_items?.map(
                  (selectionItem, nestedIndex) => (
                    <Box
                      key={selectionItem?.id}
                      sx={{
                        mb: -1.5,
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'capitalize',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ flex: 3, color: 'text.tile' }}
                      >
                        {selectionItem?.name}
                      </Typography>
                      {selectionItem?.price !== 0 && (
                        <Typography
                          variant="body2"
                          sx={{
                            flex: 1,
                            textAlign: 'center',
                            color: 'text.tile'
                          }}
                        >
                          {currency} {selectionItem?.price}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
                <Divider />
              </Box>
            ))}
          {Boolean(extraOptions && extraOptions.length !== 0) && (
            <Typography fontSize={15} fontWeight={800} mt={2}>
              Extras
            </Typography>
          )}
          {Boolean(extraOptions) &&
            extraOptions?.map((extraItem: any, index: number) => (
              <Box
                key={index}
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: 3, color: 'text.tile' }}
                >
                  {extraItem.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, textAlign: 'center', color: 'text.tile' }}
                >
                  {currency} {extraItem.price}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      {/* Drawer Footer */}
    </SwipeableDrawer>
  );
}

export { ItemDrawer };
