import * as process from "process";
import CryptoJs from "crypto-js";
// import * as dotenv from "dotenv"
import moment from "moment";

interface GqlTokenData {
    url: string
    expiresIn: string
}
const server = process.env.REACT_APP_SPA_LIVE_SERVER_URL || "";
export const getInputData = (spa_id: string) =>{
    let inputData: GqlTokenData = {
        url: spa_id,
        expiresIn: moment().add(1, "day").format("YYYY-MM-DD HH:mm:ss")
    }
    return inputData;
}
export const getApiEncryptKey = (tokenData: GqlTokenData) : string => {
    let strValue = JSON.stringify(tokenData);
    const secretKey = process.env.REACT_APP_GA_TOKEN_SECRET || "";
    // console.log("token data", strValue);
    // console.log("secret key", secretKey);
    return CryptoJs.AES.encrypt(strValue, secretKey).toString();
}

export const requestWithToken = async (endpoint: string, options?: any) =>{
    const url = `${server}/api/${endpoint}`;
    const inputData = getInputData(options?.spa_id);
    const token = getApiEncryptKey(inputData);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    return await fetch(url, {
        method: options?.method || "GET",
        headers,
        body: JSON.stringify(options?.body) || null
    });
}
