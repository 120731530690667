import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function FrontDeskIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 35 34" {...props}>
      <g clipPath="url(#clip0_446_604)">
        <path
          d="M9.97148 7.74228C10.7371 7.74228 11.4856 7.51524 12.1222 7.08988C12.7588 6.66451 13.255 6.05992 13.5479 5.35256C13.8409 4.6452 13.9176 3.86685 13.7682 3.11592C13.6189 2.36499 13.2502 1.67522 12.7088 1.13383C12.1674 0.592444 11.4776 0.223753 10.7267 0.0743846C9.97578 -0.0749842 9.19742 0.00167731 8.49006 0.294675C7.7827 0.587672 7.17811 1.08385 6.75275 1.72045C6.32738 2.35706 6.10034 3.1055 6.10034 3.87114C6.10157 4.89745 6.50982 5.88138 7.23553 6.60709C7.96124 7.3328 8.94517 7.74105 9.97148 7.74228ZM9.97148 1.9927C10.343 1.9927 10.7062 2.10286 11.0151 2.30927C11.324 2.51568 11.5648 2.80905 11.7069 3.15229C11.8491 3.49553 11.8863 3.87322 11.8138 4.23761C11.7414 4.60199 11.5624 4.9367 11.2997 5.1994C11.037 5.46211 10.7023 5.64101 10.3379 5.71349C9.97357 5.78597 9.59587 5.74877 9.25263 5.6066C8.90939 5.46442 8.61602 5.22366 8.40961 4.91475C8.2032 4.60584 8.09304 4.24266 8.09304 3.87114C8.09356 3.37311 8.29164 2.89562 8.6438 2.54346C8.99596 2.1913 9.47345 1.99322 9.97148 1.9927Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M31.6838 17.3045V11.9847H20.6476V17.3045H16.2072V12.4557C16.2057 11.2071 15.709 10.0101 14.8262 9.12712C13.9434 8.24418 12.7464 7.74736 11.4978 7.7456H8.44836C7.19966 7.74718 6.00255 8.24392 5.11959 9.12689C4.23662 10.0099 3.73987 11.207 3.73829 12.4557V17.3045H0L0.00398539 34H34.0086V17.3045H31.6838ZM22.641 13.9781H29.6911V17.3045H22.641V13.9781ZM5.73165 12.4503C5.73235 11.73 6.0188 11.0395 6.52813 10.5301C7.03746 10.0208 7.72806 9.73435 8.44836 9.73364H8.97509V13.9362H10.9678V9.73563H11.4952C12.2152 9.73739 12.9051 10.0242 13.4142 10.5333C13.9233 11.0424 14.2101 11.7324 14.2119 12.4523V17.3012H5.73165V12.4503ZM32.0159 19.2979V21.2906H1.99269V19.2979H32.0159ZM1.99668 32.0066L1.99269 23.2833H32.0159V32.0066H1.99668Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_446_604">
          <rect width="34.0086" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { FrontDeskIcon };
