import React from 'react';
import { FixedSizeList as List } from 'react-window';
import FoodItemCard from './FoodItemCard';

const ItemRenderer = ({
  index,
  style,
  data
}: {
  index: number;
  style: any;
  data: any;
}) => {
  const item = data?.items?.[index];

  return (
    <div style={style}>
      <FoodItemCard
        key={item.id}
        item={item}
        currentSubCategory={data.currentSubCategory}
      />
    </div>
  );
};

const FoodItemList = ({
  items,
  search,
  currentSubCategory
}: {
  items: any;
  search: string;
  currentSubCategory: string;
}) => {
  const sortedAndFilteredItems =
    items
      ?.sort(
        (a: { sequence: any }, b: { sequence: any }) =>
          (a.sequence || 0) - (b.sequence || 0)
      )
      ?.filter((menu: { name: string }) =>
        menu.name?.toLowerCase().includes(search?.toLowerCase())
      ) || [];

  return (
    <List
      height={700} // Adjust the height based on your requirement
      itemCount={sortedAndFilteredItems.length}
      itemSize={125} // Adjust the item height based on your item size
      width="100%" // Adjust the width based on your layout
      itemData={{ items: sortedAndFilteredItems, currentSubCategory }}
    >
      {ItemRenderer}
    </List>
  );
};

export default FoodItemList;
