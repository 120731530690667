import create from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const CHAT_SESSION = "CHAT_SESSION";

let store: any = (set: any) => ({
  chat: [],
  addToChat: (chatObject: any) => {
    set((state: any) => {
      state.chat.push(chatObject);
    });
  },
  resetChat: () => {
    set((state: any) => {
      state.chat = [];
    });
  },
});

store = immer(store);
store = persist(store, {
  name: CHAT_SESSION,
  getStorage: () => sessionStorage,
});

const useChat = create(store);

export { useChat };
