import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ButtonSection = () => {
    const navigation = useNavigate();
    return (
        <Box sx={{px: 1.5, py: 2, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 2}}>
            <Chip
                variant="filled"
                // color="primary"
                sx={{
                    flex: 1,
                    bgcolor: 'primary.main',
                    color: 'white',
                    py: 1,
                    // [`& .${chipClasses.icon}`]: {
                    //   color: 'white'
                    // }
                }}
                label={"Let's Chat"}
                // icon={<WifiCalling3Icon fontSize="small" />}
                onClick={e => {
                    e.preventDefault();
                    navigation("/chat");
                }}
            />
            <Chip
                variant="filled"
                // color="primary"
                sx={{
                    flex: 1,
                    bgcolor: 'primary.main',
                    color: 'white',
                    py: 1,

                    // [`& .${chipClasses.icon}`]: {
                    //   color: 'white'
                    // }
                }}
                label={"Feedback"}
                // icon={<WifiCalling3Icon fontSize="small" />}
                onClick={e => {
                    e.preventDefault();
                    navigation("./feedback");

                }}
            />
        </Box>);
}

export default ButtonSection;