import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function MilkIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clipPath="url(#clip0_37_99)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#FCB415"
        />
        <path d="M61.8896 150V91.05H108.8V150H61.8896Z" fill="black" />
        <path d="M114.67 150V91.05H138.12V150H114.67Z" fill="black" />
        <path
          d="M120.47 67.61C120.27 67.92 120.15 68.14 120.01 68.35C116.437 73.6833 112.87 79.0466 109.31 84.44C109.193 84.6649 109.014 84.8514 108.795 84.9773C108.575 85.1032 108.323 85.1631 108.07 85.15H62.6302C62.4502 85.15 62.2602 85.15 61.9502 85.15C62.1602 84.81 62.3102 84.53 62.4902 84.27L73.0902 68.35C73.1977 68.1205 73.3689 67.9269 73.5835 67.7921C73.798 67.6574 74.0468 67.5872 74.3002 67.59C89.5402 67.59 104.784 67.59 120.03 67.59C120.12 67.53 120.22 67.55 120.47 67.61Z"
          fill="black"
        />
        <path d="M126.36 50V61.61H73.7002V50H126.36Z" fill="black" />
        <path d="M138.11 85.06H114.69L126.36 67.5L138.11 85.06Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_37_99">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { MilkIcon };
