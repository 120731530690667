import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function BreakfastIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 38 34" {...props}>
      <g clipPath="url(#clip0_15_1824)">
        <path
          d="M34.8138 27.3604C37.263 23.9944 34.7396 19.1023 30.5254 19.1422H20.4844V11.2116H4.15625V14.8999H3.89648C-1.26691 15.0954 -1.2632 22.452 3.89648 22.6453H4.15625V24.9018C4.15539 25.7707 4.39966 26.6225 4.86133 27.3604H0V28.5672C0.00157164 30.0074 0.577901 31.3881 1.60253 32.4065C2.62717 33.4249 4.01642 33.9977 5.46547 33.9993H32.5345C33.9835 33.9977 35.3726 33.425 36.3972 32.4068C37.4218 31.3886 37.9982 30.008 38 28.5679V27.3611L34.8138 27.3604ZM25.126 21.3522C25.7872 21.3529 26.421 21.6143 26.8885 22.079C27.356 22.5436 27.619 23.1736 27.6198 23.8307V24.0203H29.8463V23.8307C29.8471 22.9543 29.5997 22.0954 29.1323 21.3522H30.5254C34.5288 21.5041 34.5258 27.2077 30.5254 27.3574H19.498C15.5971 27.2305 15.4575 21.6908 19.3303 21.3566C19.9644 21.396 20.5595 21.6741 20.9945 22.1343C21.4296 22.5945 21.6718 23.2022 21.6719 23.8336V24.0232H23.8984V23.8336C23.8992 22.9572 23.6518 22.0983 23.1845 21.3551L25.126 21.3522ZM3.89945 20.4301C1.68625 20.3467 1.68848 17.1933 3.89945 17.1107H4.15625V20.4301H3.89945ZM6.38281 24.9025V13.4253H18.2578V19.2867C14.7436 20.0716 13.0529 24.4311 15.2089 27.3582H8.85355C8.1987 27.3568 7.57105 27.0976 7.10799 26.6374C6.64494 26.1772 6.38419 25.5534 6.38281 24.9025ZM32.5368 31.787H5.46547C4.78368 31.7862 4.11946 31.572 3.5671 31.1748C3.01474 30.7775 2.60232 30.2175 2.38836 29.5741H35.6116C35.3976 30.2174 34.9851 30.7774 34.4328 31.1746C33.8805 31.5719 33.2163 31.7861 32.5345 31.787H32.5368Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M9.15124 6.98338H9.9194C10.0884 6.98328 10.2558 7.01627 10.412 7.08046C10.5681 7.14465 10.7101 7.23879 10.8296 7.3575C10.9492 7.4762 11.0441 7.61716 11.1088 7.77231C11.1736 7.92745 11.207 8.09376 11.2071 8.26174V9.36822H13.4337V8.26174C13.4327 7.33608 13.0623 6.44862 12.4037 5.79408C11.7451 5.13954 10.8522 4.77139 9.92088 4.77042H9.15124C7.4442 4.7055 7.4442 2.27714 9.15124 2.21297H15.6602V0H9.15124C4.49624 0.1763 4.49995 6.80856 9.15124 6.98338Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1824">
          <rect width="38" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { BreakfastIcon };
