import * as React from 'react';

import {
  usePropertyDetailsQuery,
  useHotelDetailsLazyQuery
} from '../../generated/graphql';
import { useDomain } from '../../utils';

const useHotelService = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        propertyID
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, hotelDetailsQuery]);

  return {
    loading: loadingMeta || loadingProperty,
    hotelService: propertyMeta?.getMeta?.hotel_services,
    diningImg: propertyMeta?.getMeta?.dining_image,
    diningType: propertyDetails?.getPropertyByDomain?.by_dining,
    show_talk_to_us: propertyDetails?.getPropertyByDomain?.show_talk_to_us,
    chatText: propertyDetails?.getPropertyByDomain?.chat_text
  };
};

export default useHotelService;
