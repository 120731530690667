import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function SoupIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 34 34" {...props}>
      <g clipPath="url(#clip0_15_1860)">
        <path
          d="M32.0078 17.0664V16.0703H1.99219V17.0664C1.99299 19.2558 2.54887 21.4092 3.60788 23.3253C4.66689 25.2415 6.19445 26.8579 8.04777 28.0234H0V29.0195C0.00158166 30.3399 0.526816 31.6058 1.46049 32.5395C2.39417 33.4732 3.66005 33.9984 4.98047 34H29.0195C30.34 33.9986 31.606 33.4734 32.5397 32.5397C33.4734 31.606 33.9986 30.34 34 29.0195V28.0234H25.9516C27.805 26.858 29.3327 25.2416 30.3918 23.3255C31.451 21.4093 32.0069 19.2558 32.0078 17.0664ZM4.02953 18.0625H29.9705C29.4658 23.6406 24.7649 28.0234 19.0586 28.0234H14.9414C9.23512 28.0234 4.53355 23.6406 4.02953 18.0625ZM31.8371 30.0156C31.6305 30.5979 31.2487 31.1019 30.7443 31.4585C30.2398 31.8152 29.6373 32.0071 29.0195 32.0078H4.98047C4.36265 32.0071 3.76019 31.8152 3.25573 31.4585C2.75127 31.1019 2.36952 30.5979 2.16285 30.0156H31.8371Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M16.8944 2.98829C16.8944 1.48618 15.7024 0.375864 15.5663 0.254341C15.4295 0.405083 16.0596 -0.294175 14.2321 1.73387C14.4061 1.89325 14.9015 2.46434 14.9015 2.98829C14.9015 4.05079 13.8218 4.2779 13.2168 5.54891L15.0157 6.40422C15.3856 5.6286 16.8944 5.01102 16.8944 2.98829Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M20.8788 2.98829C20.8788 1.48618 19.6834 0.375864 19.5506 0.254341C19.4138 0.405083 20.0447 -0.294175 18.2172 1.73387C18.3905 1.89325 18.8859 2.46434 18.8859 2.98829C18.8859 4.05079 17.8061 4.2779 17.2012 5.54891L19.0008 6.40422C19.37 5.6286 20.8788 5.01102 20.8788 2.98829Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M30.9453 0C26.3387 0 22.533 3.49297 22.0363 7.96875H10.0273V8.96484C10.0289 10.2853 10.5542 11.5511 11.4878 12.4848C12.4215 13.4185 13.6874 13.9437 15.0078 13.9453H18.9922C20.3126 13.9437 21.5785 13.4185 22.5122 12.4848C23.4458 11.5511 23.9711 10.2853 23.9727 8.96484C23.9748 7.11623 24.7101 5.34393 26.0172 4.03676C27.3244 2.72959 29.0967 1.9943 30.9453 1.99219H34V0H30.9453ZM18.9922 11.9531H15.0078C14.39 11.9524 13.7875 11.7605 13.2831 11.4039C12.7786 11.0472 12.3969 10.5432 12.1902 9.96094H21.8098C21.6031 10.5432 21.2214 11.0472 20.7169 11.4039C20.2125 11.7605 19.61 11.9524 18.9922 11.9531Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1860">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { SoupIcon };
