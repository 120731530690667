import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function NavBasketIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 64 64" {...props} width="24px" height="24px">
            <g id="Layer_72" data-name="Layer 72">
                <path d="m56.87 50.6-4.95-29.37a6 6 0 0 0 -5.93-5h-2.31v-2a11.68 11.68 0 0 0 -23.36 0v2h-2.32a6 6 0 0 0 -5.93 5l-4.94 
            29.37a9.35 9.35 0 0 0 9.22 10.9h31.3a9.35 9.35 0 0 0 9.22-10.9zm-33.55-36.42a8.68 8.68 0 0 1 17.36 0v2h-17.36zm29.18 42.07a6.35 6.35 0 0 1 -4.85 2.25h-31.3a6.34 
            6.34 0 0 1 -6.26-7.4l4.91-29.38a3 3 0 0 1 3-2.51h2.31v4.36a1.5 1.5 0 0 0 3 0v-4.36h17.37v4.36a1.5 1.5 0 0 0 3 0v-4.36h2.32a3 3 0 0 1 3 2.51l4.91 29.38a6.36 6.36 
            0 0 1 -1.41 5.15z"/>
            </g>
        </SvgIcon>
    );
}

export { NavBasketIcon };
