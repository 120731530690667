import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { Box, SwipeableDrawer, Typography, Button } from '@mui/material';
import { imageFallback } from '../../utils';
import { Layout } from '../../view/InRoomDining';

import { TalkToUS } from '../TalkToUs';
import {
  MENU_ITEM_FONT,
  DETAIL_TITLE_FONT,
  DETAIL_CONTENT_FONT
} from '../../constants';
import { SpaData } from '../../view/Spa/type';
import { useLocation, useNavigate } from 'react-router-dom';
import { split } from 'lodash';
import useHotelInfo from '../../view/HotelInfo/hotel-info-hooks';
interface SpaDrawerProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  data: SpaData;
  children?: React.ReactNode;
  noti_msg?: string;
}
function SpaDrawer(props: SpaDrawerProps) {
  const { open, handleOpen, handleClose, data, noti_msg }: SpaDrawerProps =
    props;

  const { propertyDetails } = useHotelInfo();
  const showChatIcon = propertyDetails?.getPropertyByDomain?.show_talk_to_us;
  const chatText = propertyDetails?.getPropertyByDomain?.chat_text;
  const server = process.env.REACT_APP_SPA_LIVE_SERVER_URL || '';
  const imageUrl = data?.imageList[0]
    ? server + '/' + data?.imageList[0].path
    : '/assets/no.jpg';
  const backgroundStyles = {
    background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(100, 100, 100, 0)), url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '0px 0px 30px 30px'
  };

  const barStyles = {
    background: 'rgba(255, 224, 146, 1)',
    height: '56px',
    width: '100%',
    borderRadius: '5px'
  };
  const contentPanel = {
    background: 'rgba(255, 255, 255, 1)',
    height: '100%',
    width: '100%',
    borderRadius: '10px'
  };
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const roomId = split(currentUrl, '/')[1];
  const gotoSelectSlot = () => {
    navigate(`/${roomId}/spa/${data?.id}`, { state: { data: noti_msg } });
  };
  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Layout sx={{}}>
        <Box
          onError={imageFallback}
          sx={{
            px: 2,
            py: 3,
            height: '259px',
            display: 'flex',
            justifyContent: 'space-between',
            '@media(max-width:280px)': {
              flexDirection: 'column'
            },
            boxShadow:
              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
            ...backgroundStyles,
            zIndex: 0
            // ...(sx ? sx : {})
          }}
        >
          <Typography
            variant="h6"
            sx={{
              ml: 1,
              cursor: 'pointer',
              ...MENU_ITEM_FONT,
              display: 'flex'
            }}
            onClick={handleClose}
          >
            <ArrowBackIosNewOutlined
              sx={{ mr: '4px', mt: '-2px' }}
            ></ArrowBackIosNewOutlined>
            {data?.name}
          </Typography>
          {showChatIcon && <TalkToUS sx={{}} chat_text={chatText}></TalkToUS>}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '230px',
            px: 2,
            overflow: 'hidden',
            zIndex: 1,
            width: '100%'
          }}
        >
          <Box
            sx={{
              ...barStyles,
              px: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" sx={{ ml: 1, ...DETAIL_TITLE_FONT }}>
              {data?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '317px',
            px: 2,
            zIndex: 1,
            width: '100%',
            height: 'calc(100% - 350px)',
            boxShadow: '25px 50px 70px -20px rgba(0, 0, 0, 0.15)'
          }}
        >
          <Box
            sx={{
              ...contentPanel,
              ...DETAIL_CONTENT_FONT,
              overflow: 'auto',
              scrollbarWidth: 'none',
              p: 2
            }}
          >
            {data?.description?.toString().startsWith('<') ? (
              <div
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: data?.description?.toString() || ''
                }}
              ></div>
            ) : (
              <Typography sx={{ color: 'text.secondary' }}>
                {data?.description}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '8px',
            px: 1,
            zIndex: 1000,
            width: '100%',
            boxShadow: '25px 50px 70px -20px rgba(0, 0, 0, 0.15)'
          }}
        >
          <Button
            key={data?.id}
            sx={{
              width: '100%',
              height: '40px',
              borderRadius: '4px',
              backgroundColor: 'rgba(178, 162, 121, 1)',
              border: '0.4px, solid, rgba(114, 104, 79, 1)',
              '&:hover': {
                backgroundColor: 'rgba(178, 162, 121, 1)'
              },
              color: 'rgba(255, 255, 255, 1)'
            }}
            onClick={gotoSelectSlot}
          >
            Select Slot
          </Button>
        </Box>
      </Layout>
    </SwipeableDrawer>
  );
}

export { SpaDrawer };
