import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { useMenuItem } from '../../view/InRoomDining/room-dining-hook';

export default function AllergenIcon({ source }: { source: string }) {
  const { AllergensList } = useMenuItem();
  const foundAllergenIconName = AllergensList?.find(
    (allergen: any) => allergen.uploaded_icon === source
  );
  return (
    <Tooltip
      title={foundAllergenIconName ? foundAllergenIconName?.name : source}
    >
      <Box
        component={'img'}
        src={source}
        style={{
          width: '28px',
          height: '28px',
          borderRadius: '50%',
          marginRight: '5px'
        }}
      ></Box>
    </Tooltip>
  );
}
