import React, { useContext, createContext }  from "react";
import { GetTaskHistoryQuery, useGetTaskHistoryQuery } from "../generated/graphql";
import { useAuth } from "../store";

type Props = {
    children?: React.ReactNode;
  };

type ContextType = {
    taskHistoryData: GetTaskHistoryQuery | undefined,
    loadingTaskHistory: boolean
    taskHistoryRefetch: ()=>void
}

const Context = createContext({} as ContextType);
export const useTaskHistoryContext = () => {
    return useContext(Context)
}
export default function TaskHistoryProvider({children}:Props) {
    const guest = useAuth((state: any) => state.guest);
    const { data: taskHistoryData, loading: loadingTaskHistory, refetch: taskHistoryRefetch } =
    useGetTaskHistoryQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
      variables: {
        guestID: guest?.guest_id,
        propertyID: guest?.property_id,
        roomID: guest?.room_id
      },
      skip: !guest?.guest_id
    });

    return (
        <Context.Provider value={{taskHistoryData,loadingTaskHistory,taskHistoryRefetch}}>
            {children}
        </Context.Provider>
    )
}