import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Paper } from '.';
// import DineInDialog from '../../components/DineInDialog';
// import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import AllergenIcon from '../../components/Icons/AllergenIcon';
import { CHILLI_ICONS, ALLERGEN_ICONS } from '../../constant/services';
import { imageFallback } from '../../utils';
import useInfo from '../Dashboard/components/InfoCard';
import { useMenuItem } from './e-menu-hook';
import { DineInItem } from '../../generated/graphql';
// import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';

interface MenuItemProps {
  item: any;
  // currentCategory: string;
  handleOpen: (d: DineInItem) => void;
}

const MenuItemCard = ({ item, handleOpen }: MenuItemProps) => {
  const { currency } = useInfo();
  const {
    name,
    price,
    img,
    // id,
    isVegan,
    desc,
    allergens,
    // type,
    is_recommended,
    spice_level
    // extraOptions,
    // selections
  } = item;

  // const ALL_MENU = 'ALL_MENU';
  const chili = (CHILLI_ICONS as any)[spice_level];
  // const [open, setOpen] = React.useState(false);
  // const[Icon,setIcon]=React.useState(false)
  const { ALLERGEN_NAMES } = useMenuItem();

  // const handleIconOpen=()=>{
  //   setIcon(true)
  // }
  // const hanldleIconClose=()=>{
  //   setIcon(false)
  // }
  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
      {img ? (
        <Box
          src={img}
          alt={name}
          width={70}
          height={70}
          component="img"
          onError={imageFallback}
          style={{ borderRadius: '10px', objectFit: 'cover' }}
          onClick={() => handleOpen(item)}
        />
      ) : (
        ''
      )}
      <Box sx={{ ml: 1.2, flex: 1 }}>
        <Box sx={{ display: 'flex' }}>
          {is_recommended === true && (
            <Tooltip
              enterTouchDelay={0}
              title="Chef's Recommendation"
              sx={{ mr: 0.5 }}
            >
              <Box
                component="img"
                onError={imageFallback}
                style={{
                  height: '20px',
                  width: '20x',
                  marginLeft: '5px',
                  marginTop: '-4px'
                }}
                src={`/assets/chef.svg`}
              />
            </Tooltip>
          )}
          <Typography variant="body2" sx={{ color: 'text.tile' }}>
            {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '5px' }}>
          {desc && (
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
              onClick={() => handleOpen(item)}
            >
              {desc.slice(0, 40)}...
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {allergens?.map((all: any, index: number) => {
            const icon = (ALLERGEN_ICONS as any)[all];
            const name = (ALLERGEN_NAMES as any)[all];
            return icon ? (
              <Tooltip
                enterTouchDelay={0}
                title={name || ''}
                key={index}
                style={{ marginRight: '5px' }}
              >
                <Typography sx={{ pr: 1, mr: -2.5 }}>{icon}</Typography>
              </Tooltip>
            ) : (
              <AllergenIcon source={all} key={index} />
            );
          })}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {isVegan === 'Vegan' && (
            <Tooltip enterTouchDelay={0} title="Vegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/veg.png`}
              />
            </Tooltip>
          )}
          {isVegan === 'NonVegan' && (
            <Tooltip enterTouchDelay={0} title="NonVegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/non-veg.png`}
              />
            </Tooltip>
          )}
          <Typography>{chili}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'flex-end'
        }}
      >
        <Typography variant="body2" mr="8px" sx={{ color: 'text.tile' }}>
          {/* <Typography variant="body2" sx={{ marginRight: '-50px', marginTop: '-10px' }}> */}
          {currency} {price}
        </Typography>
      </Box>

      {/* {(item?.extraOptions?.length !== 0 || item?.selections) && (
          <ExtraItemDrawer
            open={open}
            currentItem={item}
            toggleDrawer={toggleDrawer}
          />
        )} */}
    </Paper>
  );
};

export default MenuItemCard;
