const useDomain = () => {
  const hostname = window.location.hostname;
  let propertyID = process.env.REACT_APP_PROPERTY_ID;
  if (hostname !== "localhost") {
    propertyID = hostname.split(".")[0];
  }

  return propertyID;
};

export { useDomain };