import { Box} from '@mui/material';
import * as React from 'react';
import PromotionDialog from '../../components/PromotionDialog';

interface promotionProps {
  item: any;
}
const PromotionCard = ({ item }: promotionProps) => {
  const { id, description, name, banner_image } = item;
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'contain'
        }}
        key={id}
        component="img"
        alt={name}
        onError={name}
        src={banner_image}
        onClick={handleOpen}
      />
      <PromotionDialog
        desc={description}
        open={openDialog}
        handleDialogClose={handleClose}
        title={name}
      />
    </React.Fragment>
  );
};

export default PromotionCard;
