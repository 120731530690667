import useVerify from "./verify-hook";
import { Box, Button, TextField, Typography } from "@mui/material";

import { imageFallback } from "../../utils";
import { FullScreenLoader } from "../../components";

const VerifyUser = () => {
  const {
    imgUrl,
    loading,
    errorsVerify,
    registerVerify,
    onSubmitVerify,
    handleSubmitVerify,
  } = useVerify();

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      sx={{
        p: 2,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        width: {
          xs: '90%', // 0px to 600px
          sm: '80%', // 600px to 900px
          md: '60%', // 900px to 1200px
          lg: '60%', // 1200px and up
        },
        justifyContent: "center",
        margin: 'auto', // Auto margins to center horizontally and vertically
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Translate to adjust centering
      }}
      gap={2}
      onSubmit={handleSubmitVerify(onSubmitVerify)}
    >
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        mb: 3, 
        textAlign: "center", 
        alignItems: "center",
      }}>
        <Box
          maxHeight="100px"
          sx={{
            objectFit:'contain',
            maxWidth:"80%",
          }}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      <Typography variant="h6" component="div" sx={{ color: "text.secondary" }}>
        Please enter your details
      </Typography>
      <TextField
        required
        sx={{ mt: 1, input: {color:"text.tile", } }}
        variant="outlined"
        label="Reservation ID or Last Name"
        {...registerVerify("name", {
          maxLength: 30,
          required: "Please enter Last Name or Reservationd ID!",
        })}
        error={Boolean(errorsVerify.name)}
        helperText={errorsVerify.name?.message}
      />
      <TextField
        required
        sx={{ mt: 2, input: {color:"text.tile"} }}
        variant="outlined"
        label="Room Number"
        {...registerVerify("roomNo", {
          maxLength: 30,
          required: "Please enter the room number!",
        })}
        error={Boolean(errorsVerify.roomNo)}
        helperText={errorsVerify.roomNo?.message}
      />

      <Button type="submit" sx={{ mt: 3 }} variant="outlined" color="primary">
        Verify
      </Button>

      {loading && <FullScreenLoader />}
    </Box>
  );
};

export default VerifyUser;
