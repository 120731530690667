import gql from "graphql-tag";

const query = gql`
  query Me($propertyID: String!, $roomID: String!) {
    getGuest(propertyID: $propertyID, roomID: $roomID) {
      code
      message
      property_id
      guest_id
      guest_name
      room
    }
  }
`;

export default query;
