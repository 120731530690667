import { Box, Typography } from "@mui/material";

interface Props {
    treatment: any;
    handleOpen: () => void;
    setCurrentTreatment: (treatment: any) => void;
}

const SpaCard = (props : Props) => {
    const {treatment, handleOpen, setCurrentTreatment} = props;
    const server = process.env.REACT_APP_SPA_LIVE_SERVER_URL
    return (<Box
        sx={{
            borderRadius: "5px",
            p: 1,
            display: "flex",
            backgroundColor: "#FFFFFF",
            flexDirection: "column",
            boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
    >
        <Box
            key={treatment?.id}
            sx={{
                background: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9)), url(${server + "/" + treatment?.imageList[0]?.path})`,
                width: "100%",
                borderRadius: "5px",
                border: "0.2px solid #455A64",
                height: "160px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                p: 1.5,
            }}
            onClick={() => {
                handleOpen();
                setCurrentTreatment(treatment);
            }}
        >
            <Typography sx={{ fontWeight: 600, fontSize: "15px", color: "#FFFFFF", lineHeight: "18px", }}>{treatment?.name}</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "11px", color: "#FFFFFF", lineHeight: "13.2px", }}>{treatment?.description}</Typography>
        </Box>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 1,
            }}
        >
            <Typography>Durations</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                }}
            >
                {treatment?.durationList?.map((duration: any) =>
                    <Box
                        key={duration?.id}
                        sx={{
                            borderRadius: "5px",
                            px: 1.5,
                            py: 0.8,
                            height: "50px",
                            // width: "63px",
                            display: "flex",
                            backgroundColor: "#FFFFFF",
                            flexDirection: "column",
                            boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.25)",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "16.8px",
                            }}
                        >
                            {duration?.duration} mins
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "16.8px",
                            }}
                        >
                            AED {duration?.price}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    </Box>
    );
}

export default SpaCard;