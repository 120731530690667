import { useEffect, useState } from 'react';
import { useSweetAlert } from '../../utils';
import {
  useGetPropertyTreatmentsQuery,
  useUpsertBookingMutation
} from '../../generated/graphql';
import dayjs, { Dayjs } from 'dayjs';

import { useAuth } from '../../store';
import { useForm } from 'react-hook-form';
import useHotelInfo from '../../view/HotelInfo/hotel-info-hooks';

const useSpaForm = () => {
  const sweetAlert = useSweetAlert();
  const guest = useAuth((state: any) => state.guest);
  const [selectedTreatment, setSelectedTreatment] = useState<any>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [bookingTime, setBookingTime] = useState<Dayjs | null>(dayjs());
  const { spa_id } = useHotelInfo();
  const { data: Treatments, loading: loadingTreatments } =
    useGetPropertyTreatmentsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: spa_id || ''
      }
    });

  const { register, handleSubmit, control, reset } = useForm<any>({
    defaultValues: {
      guest_name: guest?.guest_name,
      guest_email: '',
      guest_room_number: guest?.room,
      guest_phone: '',
      guest_type: 'GUEST',
      status: 'PENDING',
      amount: 0,
      hours: '0',
      treatments: [],
      assigned_to:["1"]
    }
  });
  useEffect(() => {
    reset({
      name: '',
      duration: '',
      description: '',
      type: '',
      venue: ''
    });
  }, [reset, isDialogOpen]);
  const [upsertBooking] = useUpsertBookingMutation();

  const filteredTreatments = Treatments?.getPropertyTreatments?.filter(
    treatment => treatment?.id === selectedTreatment
  );
  const newTreatments = filteredTreatments?.map(treatment => ({
    id: treatment?.id,
    price: treatment?.price,
    duration: '30',
    assigned_to: ['1']
  }));
  const onSave = async (data: any) => {
    try {
      const res = await upsertBooking({
        variables: {
          bookingInput: {
            id: data?.id || '',
            property_id: spa_id || '',
            guest_name: guest?.guest_name,
            guest_email: data?.guest_email,
            guest_room_number: guest?.room,
            guest_phone: data?.guest_phone,
            guest_type: 'GUEST',
            status: 'PENDING',
            aptmt_date_time: bookingTime?.toISOString() || "",
            hours: '0',
            amount: 0,
            treatments: newTreatments ? newTreatments : selectedTreatment,
            assigned_to:["1"]
          }
        }
      });
      if (res.data?.upsertBooking?.id) {
        sweetAlert.fire({
          text: 'Booking Send Successfully',
          icon: 'success'
        });
        setIsDialogOpen(false);
        setSelectedTreatment('');
      }
    } catch (err) {
      sweetAlert.fire({
        text: `${err}`,
        icon: 'error'
      });
    }
  };

  return {
    setSelectedTreatment,
    selectedTreatment,
    isDialogOpen,
    setIsDialogOpen,
    bookingTime,
    setBookingTime,
    onSave,
    register,
    handleSubmit,
    control,
    Treatments: Treatments?.getPropertyTreatments,
    loadingTreatments
  };
};

export default useSpaForm;
