import * as React from "react";

import {
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { useDomain } from "../../utils";

const useHotelInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  const { dir_con, about, hotel_services_image, rooms_image , directory_description, dining_image} =
    propertyMeta?.getMeta || {};

  return {
    loadingMeta,
    about,
    dir_con,
    directory_description,
    phoneNumber: dir_con?.phone,
    directionImage: dir_con?.img,
    propertyDetails,
    hotelRoomsImage: rooms_image,
    hotelDiningImage: dining_image,
    hotelServicesImage: hotel_services_image,
    loading: loadingMeta || loadingProperty,
    aboutHotel:propertyMeta?.getMeta?.is_Activated?.about,
    dining:propertyMeta?.getMeta?.is_Activated?.dining,
    hotel_services:propertyMeta?.getMeta?.is_Activated?.hotel_services,
    hotel_rooms:propertyMeta?.getMeta?.is_Activated?.hotel_rooms,
    directory:propertyMeta?.getMeta?.is_Activated?.directory,
    order_service_id:propertyMeta?.getMeta?.order_service_id,
    has_order_service_id:propertyMeta?.getMeta?.has_order_service_id,
    hide_all_menu:propertyDetails?.getPropertyByDomain?.hide_all_menu,
    spa_id:propertyDetails?.getPropertyByDomain?.spa_id,
    send_to_simphony: propertyDetails?.getPropertyByDomain?.send_to_simphony
  };
};

export default useHotelInfo;
