import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function NavRoomServiceIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 28 25" {...props} width="24px" height="24px">
            <g clipPath="url(#clip0_15_1853)">
                <path d="M25.9987 18.684V16.5C25.9941 13.4928 24.8614 10.5967 22.8247 8.38424C20.7879 6.17176 17.9952 4.80389 14.9987 4.551V2.5H15.9987C16.2639 2.5 
                16.5182 2.39464 16.7058 2.20711C16.8933 2.01957 16.9987 1.76522 16.9987 1.5C16.9987 1.23478 16.8933 0.98043 16.7058 0.792893C16.5182 0.605357 16.2639 
                0.5 15.9987 0.5H11.9987C11.7335 0.5 11.4791 0.605357 11.2916 0.792893C11.104 0.98043 10.9987 1.23478 10.9987 1.5C10.9987 1.76522 11.104 2.01957 
                11.2916 2.20711C11.4791 2.39464 11.7335 2.5 11.9987 2.5H12.9987V4.551C10.0021 4.80389 7.20944 6.17176 5.17268 8.38424C3.13593 10.5967 2.0033 13.4928 
                1.99868 16.5V18.684C1.332 18.9188 0.769933 19.382 0.412039 19.9915C0.0541444 20.601 -0.0764838 21.3176 0.0432902 22.0142C0.163064 22.7108 0.525511 
                23.3425 1.06643 23.7975C1.60736 24.2525 2.29185 24.5013 2.99868 24.5H24.9987C25.7055 24.5013 26.39 24.2525 26.9309 23.7975C27.4718 23.3425 27.8343 
                22.7108 27.9541 22.0142C28.0738 21.3176 27.9432 20.601 27.5853 19.9915C27.2274 19.382 26.6654 18.9188 25.9987 18.684ZM3.99868 16.5C3.99868 13.8478 
                5.05225 11.3043 6.92761 9.42893C8.80297 7.55357 11.3465 6.5 13.9987 6.5C16.6508 6.5 19.1944 7.55357 21.0697 9.42893C22.9451 11.3043 23.9987 
                13.8478 23.9987 16.5V18.5H3.99868V16.5ZM24.9987 22.5H2.99868C2.73346 22.5 2.47911 22.3946 2.29157 22.2071C2.10403 22.0196 1.99868 21.7652 1.99868 
                21.5C1.99868 21.2348 2.10403 20.9804 2.29157 20.7929C2.47911 20.6054 2.73346 20.5 2.99868 20.5H24.9987C25.2639 20.5 25.5182 20.6054 25.7058 
                20.7929C25.8933 20.9804 25.9987 21.2348 25.9987 21.5C25.9987 21.7652 25.8933 22.0196 25.7058 22.2071C25.5182 22.3946 25.2639 22.5 24.9987 
                22.5Z"/>
            </g>
        </SvgIcon>
    );
}

export { NavRoomServiceIcon };
