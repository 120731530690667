import { Grid } from '@mui/material';

import useHotelRooms from './hotel-rooms-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';

const HotelRooms = () => {
  const { loading, hotelRooms } = useHotelRooms();

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Hotel Rooms" />
      <Grid container spacing={2}
        sx={{
          px: 2,
          py: 0,
          flex: 1,
          mt: "0px",
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
          // ...BIG_TILE_RESPONSIVE
        }}
      >
        {Array.isArray(hotelRooms) &&
          hotelRooms
            .slice()
            .sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
            .map((hotelRoom: any) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <ServiceCard
                    showInfo={true}
                    key={hotelRoom?.room_type}
                    label={hotelRoom?.room_type}
                    description={hotelRoom?.desc}
                    image={hotelRoom?.img as string}
                    notVerified={true}
                  />
                </Grid>

              );
            })}
      </Grid>
    </Layout>
  );
};

export default HotelRooms;
