function hexToRgb(hex: string): number[] {
    // Convert hex to RGB
    const bigint: number = parseInt(hex.slice(1), 16);
    const r: number = (bigint >> 16) & 255;
    const g: number = (bigint >> 8) & 255;
    const b: number = bigint & 255;
    return [r, g, b];
}

function calculateRelativeLuminance(rgb: number[]): number {
    // Calculate relative luminance
    const [r, g, b] = rgb.map(value => {
        value /= 255;
        return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
    });

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function calculateContrastRatio(color1: string, color2: string): number {
    const rgb1: number[] = hexToRgb(color1);
    const rgb2: number[] = hexToRgb(color2);

    const luminance1: number = calculateRelativeLuminance(rgb1);
    const luminance2: number = calculateRelativeLuminance(rgb2);

    const contrastRatio: number = (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);

    return contrastRatio;
}

export default function isTextVisible(backgroundColor: string | undefined | null, textColor: string | undefined): boolean {
    if (!backgroundColor || !textColor) return true;
    const contrastRatio: number = calculateContrastRatio(backgroundColor, textColor);
    // Check against recommended WCAG ratios
    return contrastRatio >= 4.5;
};
