import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ItIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <g clipPath="url(#clip0_15_1774)">
        <path
          d="M0 0V32H32V0H0ZM30.1175 30.1175H1.8825V9.41187H30.1175V30.1175ZM30.1175 7.52938H1.8825V1.8825H30.1175V7.52938Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M3.76489 3.765H5.64677V5.64688H3.76489V3.765Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.5293 3.765H9.4118V5.64688H7.5293V3.765Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M11.2944 3.765H13.1763V5.64688H11.2944V3.765Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M3.76489 11.2944H28.2349V13.1763H3.76489V11.2944Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M28.2349 28.235V26.3531H26.353V22.5881H22.9811C22.9655 22.5494 22.9493 22.5106 22.933 22.4719L25.3174 20.0875L21.3243 16.0944L18.9374 18.4788C18.8986 18.4619 18.8599 18.4463 18.8211 18.43V15.0588H13.1761V18.43C13.1374 18.4463 13.0986 18.4619 13.0599 18.4788L10.6755 16.0944L6.68239 20.0875L9.06677 22.4719C9.05052 22.5106 9.03427 22.5494 9.01864 22.5881H5.64364V26.3531H3.76489V28.235H28.2349ZM17.6299 26.3531H14.3699C14.2046 26.067 14.1175 25.7423 14.1174 25.4118C14.1173 25.0813 14.2042 24.7566 14.3695 24.4704C14.5347 24.1841 14.7723 23.9464 15.0585 23.7811C15.3447 23.6159 15.6694 23.5289 15.9999 23.5289C16.3304 23.5289 16.6551 23.6159 16.9413 23.7811C17.2275 23.9464 17.4651 24.1841 17.6303 24.4704C17.7955 24.7566 17.8825 25.0813 17.8824 25.4118C17.8823 25.7423 17.7952 26.067 17.6299 26.3531ZM7.52927 24.4706H10.3868L10.5868 23.7981C10.6978 23.4264 10.8467 23.067 11.0311 22.7256L11.3655 22.1087L9.34489 20.0875L10.6761 18.7569L12.6968 20.7775L13.3143 20.4431C13.6552 20.2586 14.0144 20.1101 14.3861 20L15.0586 19.8V16.9412H16.9411V19.7987L17.6136 19.9988C17.986 20.1091 18.3459 20.2581 18.6874 20.4431L19.3049 20.7775L21.3261 18.7569L22.6568 20.0875L20.6361 22.1087L20.9705 22.7256C21.1549 23.067 21.3038 23.4264 21.4149 23.7981L21.6149 24.4706H24.4724V26.3531H19.6443C20.2618 23.9612 18.4449 21.6469 15.9999 21.6469C15.4255 21.6474 14.8588 21.7793 14.3432 22.0324C13.8276 22.2856 13.3768 22.6533 13.0251 23.1074C12.6734 23.5616 12.4302 24.0901 12.3142 24.6527C12.1981 25.2152 12.2123 25.7969 12.3555 26.3531H7.52927V24.4706Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1774">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { ItIcon };
