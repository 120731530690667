import { useForm } from "react-hook-form";
// import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Cache } from 'aws-amplify';

import {
  useGuestVerificationMutation,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { useAuth } from "../../store";
// import meQuery from "../../utils/meQuery";
import { useDomain, useSweetAlert } from "../../utils";

interface VerifyForm {
  name: string;
  roomNo: string;
}

const useVerify = () => {
  const sweetAlert = useSweetAlert();
  const [verifyGuest, { loading: loadingVerification }] =
    useGuestVerificationMutation();

  const domainId = useDomain();
  const setGuest = useAuth((state: any) => state.setGuest);
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  const navigate = useNavigate();
  const {
    register: registerVerify,
    handleSubmit: handleSubmitVerify,
    reset: resetVerify,
    formState: { errors: errorsVerify},
  } = useForm<VerifyForm>();

  const onSubmitVerify = async (formData: VerifyForm, e: any) => {
    e.preventDefault();
    const { name, roomNo } = formData;
    try {
      const response = await verifyGuest({
        variables: {
          guestName: name,
          propertyID: propertyDetails?.getPropertyByDomain?.id || "",
          roomID: roomNo ,
        },
      });

      if (response.data?.verifyGuest?.code === "200") {
        // const { data } = await getGuest({
        //   query: meQuery,
        //   variables: {
        //     propertyID: domainId,
        //     roomID: roomNo,
        //   },
        // });
        await sweetAlert
          .fire({
            text: "Verified Successfully",
            icon: "success",
            timer: 2000,
          })
          .then(() => {
            const encryptedRoom = response.data?.verifyGuest?.room;
            setGuest({ ...response.data?.verifyGuest, room_id: encryptedRoom, room:roomNo  });
            Cache.setItem('user', { ...response.data?.verifyGuest, room_id: encryptedRoom, room:roomNo  });
            resetVerify();
            navigate(`/${encryptedRoom}`);
          });
      } else {
        await sweetAlert.fire({
          text: response.data?.verifyGuest?.message,
          icon: "error",
        });
      }
    } catch (error) {}
  };

  const imgUrl = propertyDetails?.getPropertyByDomain?.img;

  return {
    imgUrl,
    resetVerify,
    errorsVerify,
    onSubmitVerify,
    registerVerify,
    handleSubmitVerify,
    loading: loadingVerification || loadingProperty,
  };
};

export default useVerify;
