import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DessertIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 33 32" {...props}>
      <g clipPath="url(#clip0_15_1837)">
        <path
          d="M28.0312 4.6875C24.9368 1.75931 20.7842 0.135072 16.4707 0.165787C12.1573 0.196503 8.02944 1.87971 4.97929 4.85165C1.92914 7.82359 0.201641 11.8456 0.170117 16.0484C0.138593 20.2513 1.80558 24.2974 4.81082 27.3125C7.90523 30.2407 12.0579 31.8649 16.3713 31.8342C20.6848 31.8035 24.8126 30.1203 27.8627 27.1483C30.9129 24.1764 32.6404 20.1544 32.6719 15.9516C32.7034 11.7487 31.0365 7.70256 28.0312 4.6875V4.6875ZM14.1054 2.0625C13.9065 2.86563 13.8963 3.37063 13.1484 4.23375C12.5172 4.96313 12.0508 5.14625 11.4613 5.37875C10.793 5.64188 9.961 5.96875 9.02064 7.055C8.08028 8.14125 7.88977 8.9925 7.73775 9.68C7.60176 10.2856 7.49464 10.7638 6.86345 11.4925C6.23227 12.2213 5.76658 12.405 5.17645 12.6369C4.50806 12.9 3.6761 13.2275 2.73574 14.3131C2.42579 14.665 2.16006 15.0517 1.94419 15.465C2.2027 8.6875 7.38816 3.11938 14.1054 2.0625ZM16.421 30.1175C9.68582 30.1175 4.01094 25.6175 2.39385 19.5419C3.04363 18.6563 3.2072 17.9375 3.33549 17.3431C3.47084 16.7375 3.57796 16.2588 4.20914 15.5306C4.84033 14.8025 5.30859 14.6169 5.90128 14.385C6.56967 14.1219 7.40099 13.7944 8.33878 12.7088C9.27658 11.6231 9.4703 10.7713 9.62168 10.0838C9.75766 9.47813 9.86414 9 10.496 8.27125C11.1278 7.5425 11.5928 7.35875 12.183 7.12625C12.8507 6.86313 13.6827 6.53625 14.6205 5.45063C15.9906 3.86875 15.7533 2.83 16.1907 1.88813C24.3006 1.76313 30.91 8.15375 30.91 16C30.91 23.7844 24.4102 30.1175 16.421 30.1175Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M16.421 10.3531C15.2747 10.3531 14.1542 10.6843 13.2012 11.3048C12.2481 11.9253 11.5053 12.8072 11.0666 13.839C10.628 14.8709 10.5132 16.0063 10.7368 17.1016C10.9605 18.197 11.5124 19.2032 12.3229 19.9929C13.1335 20.7827 14.1661 21.3205 15.2903 21.5384C16.4145 21.7563 17.5798 21.6444 18.6388 21.217C19.6978 20.7896 20.6029 20.0659 21.2397 19.1372C21.8765 18.2086 22.2164 17.1168 22.2164 16C22.2147 14.5029 21.6036 13.0675 20.5171 12.0089C19.4306 10.9502 17.9575 10.3548 16.421 10.3531V10.3531ZM16.421 19.765C15.6567 19.765 14.9096 19.5442 14.2742 19.1305C13.6388 18.7168 13.1435 18.1288 12.851 17.4408C12.5586 16.7528 12.482 15.9958 12.6311 15.2655C12.7802 14.5351 13.1483 13.8643 13.6887 13.3377C14.2291 12.8112 14.9176 12.4526 15.6671 12.3073C16.4167 12.1621 17.1936 12.2366 17.8997 12.5216C18.6058 12.8066 19.2092 13.2891 19.6338 13.9083C20.0584 14.5274 20.285 15.2553 20.285 16C20.2837 16.9981 19.8761 17.955 19.1518 18.6608C18.4274 19.3666 17.4454 19.7637 16.421 19.765V19.765Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M5.93604 20.2838L7.5435 19.2394L9.47618 22.0625L7.86872 23.1069L5.93604 20.2838Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M24.1729 22.895L24.8554 19.5656L26.7483 19.9375L26.0651 23.2663L24.1729 22.895Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M22.3533 9.92687L23.9569 8.88187L25.8902 11.705L24.286 12.75L22.3533 9.92687Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M17.7905 6.74L20.6879 4.85687L21.7598 6.42312L18.8624 8.30625L17.7905 6.74Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M17.3408 26.0337L20.2389 24.15L21.3107 25.7162L18.4133 27.5994L17.3408 26.0337Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M11.564 23.5294H13.4954V25.4119H11.564V23.5294Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M26.0806 15.0587H28.0126V16.9412H26.0806V15.0587Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1837">
          <rect width="33" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { DessertIcon };
