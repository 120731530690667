import * as React from 'react';
import { merge, cloneDeep } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';

import zIndex from './z-index';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import { FullScreenLoader } from '../components';
import { useDomain, useTitle } from '../utils';
import { usePropertyDetailsQuery } from '../generated/graphql';
import { SYSTEM_FONTS } from '../constants';
import isTextVisible from './contrast';

interface Props {
  children: React.ReactNode;
}

function useColors(): { loading: boolean; data: any } {
  const domainId = useDomain();

  const { data: propertyDetails, loading } = usePropertyDetailsQuery({
    variables: {
      domain: domainId as string
    }
  });

  const data = React.useMemo(() => {
    if (!propertyDetails) return {};

    const {
      img,
      primary_color,
      primary_light,
      name: propertyName,
      primary_text_color: primary_text,
      secondary_text_color: secondary_text,
      background_color,
      font,
      tile_color,
      navbar_background_color,
      navbar_active_background_color,
      navbar_title_color,
      navbar_active_title_color
    } = propertyDetails?.getPropertyByDomain || {};

    return {
      logoUrl: img,
      propertyName,
      text: {
        primary: primary_text,
        secondary: secondary_text,
        tile: isTextVisible(tile_color, primary_text) ? primary_text : '#000000'
      },
      color: {
        primary: primary_color,
        primaryLight: primary_light,

      },
      nav:{
        navBackground: navbar_background_color,
        navActiveBackground: navbar_active_background_color,
        navTitle: navbar_title_color,
        navActiveTitle: navbar_active_title_color
      },
      background_color,
      font,
      tile_color
    };
  }, [propertyDetails]);

  return {
    loading,
    data
  };
}

const ThemeConfig = ({ children }: Props) => {
  const { loading, data } = useColors();
  useTitle(data?.propertyName);

  const themeOptions = React.useMemo(() => {
    const { text, color, background_color, font, tile_color, nav } = data;
    console.log("nav", nav)

    let paletteCopy = cloneDeep(palette);
    let typographyCopy = cloneDeep(typography);

    if (text && color) {
      const { primary: main, primaryLight: light} = color;
      const clientThemeColors = {
        text,
        primary: { main, light},
        background: {
          paper: tile_color ? tile_color : '#FFFFFF',
          default: '#FFFFFF',
          grey: background_color ? background_color : '#F6F7FB',
          light: background_color ? background_color : '#FCFCFC',
        },
        nav: {...nav}
      };
      paletteCopy = merge(clientThemeColors, palette);
    }
    if (font) {
      const clientFont = {
        fontFamily: [
          // NOTO_SANS_DISPLAY,
          // PLAYFAIR_DISPLAY,
          // SHADOWS_INTO_LIGHT,
          font
          // ...SYSTEM_FONTS,
        ].join(','),
        fontFamilySystem: SYSTEM_FONTS.join(',')
      };
      typographyCopy = merge(clientFont, typography);
    }

    return {
      zIndex,
      typography: typographyCopy,
      palette: paletteCopy
    };
  }, [data]);

  if (loading) return <FullScreenLoader />;

  const theme = createTheme(themeOptions as ThemeOptions);
  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export { ThemeConfig };
