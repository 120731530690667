import { Outlet } from "react-router-dom";
import { Box, SxProps, Theme } from "@mui/material";
import TaskHistoryProvider from "../../context/TaskHistoryContext";
import NavBar from "../../view/Dashboard/components/NavBar";

interface LayoutProps {
  sx?: SxProps<Theme>;
  isNavBar?: boolean,
}

const Layout = ({ sx, isNavBar }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        ...(sx ? sx : {}),
      }}
    >
      <Outlet />
      {isNavBar && (<TaskHistoryProvider>
        <NavBar></NavBar>
      </TaskHistoryProvider>)}
    </Box>
  );
};

export { Layout };
