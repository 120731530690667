import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EngineeringIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 34 34" {...props}>
      <g clipPath="url(#clip0_15_1781)">
        <path
          d="M16.9999 26.767C16.0108 26.7684 15.0261 26.6343 14.0734 26.3686L13.3476 26.1641V21.2786C12.0315 20.5607 10.9555 19.4718 10.2536 18.1471C9.5517 16.8225 9.25481 15.3207 9.3999 13.8286C9.54499 12.3365 10.1256 10.9201 11.0696 9.75548C12.0136 8.59089 13.2792 7.7296 14.7089 7.27879L16.0038 6.87105V12.908H17.996V6.87105L19.2916 7.27879C20.8228 7.76731 22.1634 8.72141 23.1265 10.0081C23.7802 10.8855 24.2377 11.8932 24.4681 12.9629C24.6984 14.0326 24.6962 15.1393 24.4616 16.208C24.2269 17.2767 23.7654 18.2826 23.1081 19.1574C22.4509 20.0322 21.6134 20.7556 20.6522 21.2786V26.1641L19.9218 26.3673C18.9706 26.633 17.9875 26.7675 16.9999 26.767V26.767ZM15.3397 24.6181C16.4381 24.826 17.5657 24.826 18.664 24.6181V20.0075L19.2617 19.7466C20.2691 19.3061 21.1264 18.5817 21.7286 17.6618C22.3307 16.7418 22.6518 15.6664 22.6524 14.5669C22.6556 13.3521 22.263 12.1693 21.5341 11.1974C21.1104 10.6337 20.5864 10.1531 19.9882 9.77965V14.9002H14.0116V9.77965C13.4149 10.1541 12.8924 10.6354 12.4703 11.1994C11.7414 12.1713 11.3489 13.3541 11.3521 14.5689C11.3527 15.6684 11.6737 16.7438 12.2759 17.6638C12.8781 18.5837 13.7353 19.3081 14.7428 19.7486L15.3404 20.0095L15.3397 24.6181Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M21.1836 34H12.8164V31.2953C11.7876 30.9937 10.7947 30.5809 9.85535 30.0641L7.93953 31.9799L2.02008 26.0618L3.9359 24.146C3.41933 23.2061 3.00651 22.2128 2.70473 21.1836H0V12.8164H2.70473C3.0066 11.7877 3.41941 10.7948 3.9359 9.85535L2.02008 7.9382L7.9382 2.02008L9.85402 3.9359C10.7938 3.41905 11.7871 3.00622 12.8164 2.70473V0H21.1836V2.70473C22.2124 3.00631 23.2053 3.41914 24.1446 3.9359L26.0605 2.02008L31.9786 7.9382L30.0628 9.85402C30.5801 10.7937 30.9934 11.7871 31.2953 12.8164H34V21.1836H31.2953C30.9937 22.2124 30.5809 23.2053 30.0641 24.1446L31.9799 26.0605L26.0618 31.9786L24.146 30.0628C23.2063 30.5801 22.2129 30.9934 21.1836 31.2953V34ZM14.8086 32.0078H19.1914V29.7394L19.9591 29.5587C21.3107 29.2402 22.6015 28.7037 23.7807 27.9703L24.4514 27.5539L26.0605 29.163L29.1616 26.0618L27.5526 24.4528L27.969 23.7821C28.7023 22.6028 29.2388 21.312 29.5574 19.9604L29.7394 19.1914H32.0078V14.8086H29.7394L29.5587 14.0409C29.2402 12.6893 28.7037 11.3985 27.9703 10.2193L27.5539 9.54855L29.163 7.93953L26.0618 4.83836L24.4528 6.44605L23.7821 6.02969C22.6028 5.29633 21.312 4.75983 19.9604 4.44125L19.1914 4.26063V1.99219H14.8086V4.26063L14.0409 4.44125C12.6889 4.75982 11.3977 5.29631 10.2179 6.02969L9.54723 6.44605L7.9382 4.83703L4.8377 7.9382L6.44605 9.54723L6.02969 10.2179C5.2966 11.3973 4.76013 12.6881 4.44125 14.0396L4.26063 14.8086H1.99219V19.1914H4.26063L4.44125 19.9591C4.7597 21.3112 5.2962 22.6024 6.02969 23.7821L6.44605 24.4528L4.83703 26.0618L7.9382 29.1623L9.54656 27.5539L10.2179 27.9703C11.3972 28.7037 12.688 29.2402 14.0396 29.5587L14.8086 29.7394V32.0078Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1781">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { EngineeringIcon };
