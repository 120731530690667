import * as React from "react";

import {
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { useDomain } from "../../utils";

const useDining = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
      }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  return {
    dining:  propertyMeta?.getMeta?.hotel_services,
    loading: loadingMeta || loadingProperty,
  };
};

export default useDining;
