import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent
} from '@mui/material';
import * as React from 'react';

interface props {
  open: boolean;
  title: string;
  desc: string;
  handleDialogClose: () => void;
}

const promotionDialog = ({ open, handleDialogClose, title, desc }: props) => {
  return (
    <>
      <Dialog open={open}>
        <DialogTitle
          color={'#fff'}
          bgcolor={theme => theme.palette.primary.main}
        >
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton color={'inherit'} onClick={handleDialogClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ flex: 1, p: 1.5 }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {desc}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default promotionDialog;
