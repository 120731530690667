import { ApolloProvider } from "@apollo/client";

import { ThemeConfig } from "./theme";
import AppRouter from "./view/AppRouter";
import apolloClient from "./core/apolloClient";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeConfig>
        <AppRouter />
      </ThemeConfig>
    </ApolloProvider>
  );
}

export default App;
