import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface ServiceRequestIconProps extends SvgIconProps {
  customColor?: string;
}

function ServiceRequestIcon(props: ServiceRequestIconProps) {
  const theme = useTheme();
  const { customColor, ...restProps } = props;
  return (
    <SvgIcon viewBox="0 0 66 60" {...restProps}>
      <g clipPath="url(#clip0_446_564)">
        <path
          d="M58.9135 0H6.61142C4.85848 0.00237086 3.17804 0.699859 1.93865 1.93949C0.699259 3.17912 0.0020963 4.8597 6.47269e-05 6.61263V20.369C0.0020963 22.1219 0.699259 23.8025 1.93865 25.0421C3.17804 26.2818 4.85848 26.9793 6.61142 26.9816H7.70947V30.8363H6.47269e-05V44.068C-0.00614766 45.6633 0.434948 47.2284 1.27329 48.5857C2.11164 49.9429 3.31366 51.0381 4.74294 51.7467L6.53463 52.6425C6.8886 52.8181 7.18629 53.0893 7.39395 53.4254C7.6016 53.7616 7.71091 54.1492 7.70947 54.5443V60.0051H30.8351V54.4176L34.6898 46.7082V39.1293C34.6875 36.9306 33.813 34.8226 32.2582 33.2679C30.7035 31.7132 28.5955 30.8387 26.3968 30.8363H19.2723V26.9816H58.9135C60.6662 26.9793 62.3465 26.282 63.5858 25.0426C64.8252 23.8032 65.5225 22.123 65.5249 20.3703V6.61263C65.5229 4.8597 64.8257 3.17912 63.5863 1.93949C62.3469 0.699859 60.6665 0.00237086 58.9135 0ZM26.3968 34.6898C27.5738 34.6911 28.7021 35.1593 29.5341 35.9917C30.3662 36.824 30.8341 37.9524 30.8351 39.1293V45.7983L26.9804 53.5077V56.1466H11.5629V54.5405C11.5656 53.43 11.2575 52.341 10.6732 51.3967C10.089 50.4524 9.25209 49.6906 8.25721 49.1974L6.46552 48.3015C5.6794 47.9115 5.01812 47.3092 4.55651 46.5628C4.0949 45.8165 3.85137 44.9558 3.85349 44.0782V34.6898H7.70947V43.6841H11.5629V19.2722H15.4176V34.6898H26.3968ZM61.6702 20.3703C61.6695 21.1012 61.3789 21.802 60.862 22.3188C60.3452 22.8356 59.6444 23.1263 58.9135 23.1269H19.2723V15.4175H7.70947V23.1269H6.61142C5.88052 23.1263 5.17974 22.8356 4.66292 22.3188C4.14609 21.802 3.85544 21.1012 3.85477 20.3703V6.61263C3.85544 5.88161 4.14605 5.18071 4.66284 4.66368C5.17963 4.14665 5.8804 3.85572 6.61142 3.8547H58.9135C59.6446 3.85572 60.3453 4.14665 60.8621 4.66368C61.3789 5.18071 61.6695 5.88161 61.6702 6.61263V20.3703Z"
          fill={customColor || theme.palette.primary.main}
        />
        <path
          d="M7.70947 7.70941H35.3323V11.5641H7.70947V7.70941Z"
          fill={customColor || theme.palette.primary.main}
        />
        <path
          d="M23.127 15.4175H35.3322V19.2722H23.127V15.4175Z"
          fill={customColor || theme.palette.primary.main}
        />
        <path
          d="M45.2556 14.0776L42.577 10.9524L39.6501 13.4607L44.6797 19.3285L56.4025 10.5364L54.0899 7.45216L45.2556 14.0776Z"
          fill={customColor || theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_446_564">
          <rect width="66" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { ServiceRequestIcon };
