// TalkToUS.tsx
// import React from 'react';
import { Box, Chip, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SxProps, Theme } from '@mui/system';
import { CHAT_FONT } from '../../constants';
import { useTheme } from '@mui/material';
// import { split } from "lodash";
interface TalkToUSProps {
  sx?: SxProps<Theme>;
  chat_text: String | undefined | null;
}

export const TalkToUS = ({ sx, chat_text }: TalkToUSProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  /* const location = useLocation();
  const currentUrl = location.pathname;
  const roomId = split(currentUrl, "/")[1]; */

  const chipStyles: SxProps<Theme> = {
    backgroundColor:
      theme.palette.nav.navBackground || 'rgba(211, 200, 172, 1)',
    color: theme.palette.nav.navTitle || '#333',
    '& .MuiChip-deleteIcon': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor:
        theme.palette.nav.navBackground || 'rgba(211, 200, 172, 1)'
    },
    px: 1,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '-5px',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderLeft: `10px solid ${
        theme.palette.nav.navBackground ?? 'rgba(211, 200, 172, 1)'
      }`,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent'
    },
    ...CHAT_FONT
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={2}
      sx={{ ...(sx ? sx : {}) }}
    >
      <Chip
        variant="filled"
        label={chat_text || "Let's chat"}
        onClick={() => navigate(`/chat`)}
        sx={chipStyles}
      />
      <Box
        onClick={() => navigate(`/chat`)}
        sx={{ ml: '5px !important', mt: '5px !important' }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3333 10.6667H26.6667C27.4031 10.6667 28 11.2636 28 12V26.6667L23.556 22.9747C23.3167 22.7757 23.0144 22.6667 22.7031 22.6667H12C11.2636 22.6667 10.6667 22.0697 10.6667 21.3333V17.3333M21.3333 10.6667V6.66668C21.3333 5.9303 20.7364 5.33334 20 5.33334H5.33333C4.59696 5.33334 4 5.9303 4 6.66668V21.3337L8.44401 17.6411C8.6834 17.4423 8.98565 17.3333 9.29688 17.3333H10.6667M21.3333 10.6667V16C21.3333 16.7364 20.7364 17.3333 20 17.3333H10.6667"
            stroke="#F2F2F2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    </Stack>
  );
};
