import React from 'react';
import Card from '@mui/material/Card';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';

import { Drawer } from '../Drawer';
import useInfo from '../../view/Dashboard/components/InfoCard';
import { BIG_TILE_SIZE, SERVICE_ITEM_FONT } from '../../constants';
interface CommonProps {
  image: string;
  label?: string;
  serviceID?: number | null;
  children?: React.ReactNode;
  booking?: string;
  noti_msg?: string;
  bookingForm?: boolean;
  spaIntegration?: boolean;
  notVerified?: boolean;
}

type ConditionalProps =
  | {
      path: string;
      showInfo: false;
      description?: never;
    }
  | {
      path?: never;
      showInfo: true;
      description?: string;
    };

type DashboardProps = CommonProps & ConditionalProps;

const ServiceCard = (props: DashboardProps) => {
  const navigate = useNavigate();
  const { titleColor } = useInfo();
  const [open, setOpen] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(true);
  // const [isDetailOpened, setIsDetailOpened] = React.useState(false);
  const {
    image,
    label,
    path,
    children,
    showInfo,
    description,
    serviceID,
    booking,
    bookingForm,
    notVerified,
    spaIntegration,
    noti_msg
  } = props;

  const handleOpen = () => {
    if (showInfo) {
      setOpen(true);
      // navigate(`../service-detail/`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {showInfo && (
        <Drawer
          open={open}
          image={image}
          name={label as string}
          handleOpen={handleOpen}
          handleClose={handleClose}
          desc={description as string}
          serviceID={serviceID}
          bookingUrl={booking || ''}
          bookingForm={bookingForm || false}
          spaIntegration={spaIntegration || false}
          notVerified={notVerified}
          noti_msg={noti_msg}
        />
      )}
      <Box className="BigTile" sx={{}}>
        <Card
          sx={{
            boxShadow: '25px 50px 70px -20px rgba(0, 0, 0, 0.15) !important',
            ...(label ? { position: 'relative' } : {}),
            ...BIG_TILE_SIZE
          }}
          onClick={handleOpen}
        >
          {imageLoading && (
            <Box
              sx={{
                height: 250,
                width: '100%',
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                backgroundColor: 'white',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {image ? (
            <CardMedia
              alt={label}
              image={image}
              component="img"
              sx={{ height: 250 }}
              onLoad={() => setImageLoading(false)}
              onClick={showInfo ? () => handleOpen() : () => navigate(path)}
            />
          ) : (
            <Box>
              <CardMedia
                alt={label}
                image="/assets/no.jpg"
                component="img"
                sx={{ height: 250, width: 200, marginLeft: '45%' }}
                onLoad={() => setImageLoading(false)}
                onClick={showInfo ? () => handleOpen() : () => navigate(path)}
              />
            </Box>
          )}

          {/* Extra stuff to render */}
          {children}
        </Card>
        {label && (
          <Typography
            variant="h6"
            sx={{
              mt: 1,
              ...(image ? { color: titleColor } : { color: 'black' }),
              ...SERVICE_ITEM_FONT
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </>
  );
};

export { ServiceCard };
