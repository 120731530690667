import * as React from "react";

import { useDomain } from "../../utils";
import {
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";

const useHotelRooms = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  return {
    loading: loadingProperty || loadingMeta,
    hotelRooms: propertyMeta?.getMeta?.hotel_rooms,
  };
};

export default useHotelRooms;
