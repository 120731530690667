import { startCase } from "lodash";
import EmailIcon from "@mui/icons-material/Email";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";

import useHotelInfo from "./hotel-info-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { FullScreenLoader, ServiceCard } from "../../components";
import { Box, CardActions, CardContent, Chip, Typography } from "@mui/material";

const HotelInfo = () => {
  const {
    about,
    dir_con,
    loading,
    phoneNumber,
    propertyDetails,
    hotelRoomsImage,
    directory_description,
    hotelDiningImage,
    dining,
    directory,
    hotel_rooms,
    aboutHotel
  } = useHotelInfo();

  if (loading) {
    return <FullScreenLoader />;
  }
  return (
    <Layout>
      <HeaderLayout title="Hotel Information" />

      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          // TODO:
          // This color is only used once keeping it here for a while
          // backgroundColor: "#F4F4F4",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {aboutHotel && <ServiceCard
          showInfo={true}
          label={"About Us"}
          image={about?.img as string}
          description={about?.desc as string}
        >
          <CardContent sx={{ px: 2, pb: 0 }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                mb: 0.5,
                fontWeight: 600,
                fontSize: (theme) => theme.spacing(2.5),
              }}
            >
              {propertyDetails?.getPropertyByDomain &&
                startCase(propertyDetails?.getPropertyByDomain?.name)}
            </Typography>
            {
              dir_con?.address?.toString().startsWith("<") ? (
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: dir_con?.address?.toString() || '' }}
                ></div>
              ) : (
                <Typography
                  sx={{ color: 'text.secondary', textDecorationLine: "underline" }}
                  variant="body2"
                >
                  {dir_con?.address}
                </Typography>
              )
            }
            {/* <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecorationLine: "underline" }}
            >
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: dir_con?.address?.toString() || "" }}></div>
            </Typography> */}
          </CardContent>
          <CardActions sx={{ px: 2, py: 2 }}>
            <Chip
              variant="filled"
              color="primary"
              sx={{ flex: 1 }}
              label={'Call Us'}
              icon={<WifiCalling3Icon fontSize="small" color="primary" />}
              onClick={() => phoneNumber && window.open(`tel:${phoneNumber}`)}
            />
            <Chip
              variant="filled"
              color="primary"
              label={'Email Us'}
              sx={{ flex: 1, overflow: "hidden" }}
              icon={<EmailIcon fontSize="small" />}
              onClick={() =>
                dir_con?.email && window.open(`mailto:${dir_con.email}`)
              }
            />
          </CardActions>
        </ServiceCard>}

        {hotel_rooms && <Box sx={{ py: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Hotel Rooms'}
            path={`/hotel-rooms`}
            image={hotelRoomsImage as string}
            notVerified={true}
          />
        </Box>}

        {directory && <Box sx={{ py: 2 }}>
          <ServiceCard
            showInfo={true}
            image={dir_con?.img as string}
            description={directory_description as string}
            label={'Hotel Directory'}
            notVerified={true}
          />
        </Box>
        }
        {dining && <Box sx={{ py: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Dining'}
            path={`/dining`}
            image={hotelDiningImage as string}
            notVerified={true}
          />
        </Box>}
      </Box>
    </Layout>
  );
};
export default HotelInfo;
