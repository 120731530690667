import * as React from 'react';
import { useEffect, useState } from 'react';
import { useServiceAlert, useSweetAlert } from '../../utils';
import {
  QuickService,
  usePropertyQuickServicesQuery,
  usePropertyServicesMutationMutation
} from '../../generated/graphql';
// import { useTaskHistoryContext } from '../../context/TaskHistoryContext';
import { useAuth } from '../../store';
import dayjs, { Dayjs } from 'dayjs';

const useQuickService = () => {
  const sweetAlert = useSweetAlert();
  const serviceAlert = useServiceAlert(() => { });
  const guest = useAuth((state: any) => state.guest);
  const [tasks, setTasks] = useState<Array<QuickService | null>>([]);
  // const { taskHistoryRefetch } = useTaskHistoryContext();
  const [notes, setNotes] = React.useState<string>();
  const [open, setOpen] = useState(false);
  const [serviceCount, setServiceCount] = React.useState(1);

  const { data: quickServices, loading: ServiceLoading } =
    usePropertyQuickServicesQuery({
      variables: {
        propertyID: guest?.property_id
      }
    });

  useEffect(() => {
    const srvces = quickServices?.getQuickServices?.quicktasklist || [];
    if (srvces.length) {
      setTasks([...srvces]);
    }
  }, [quickServices?.getQuickServices?.quicktasklist]);

  const [requestService, { loading: loadingSubmitService }] =
    usePropertyServicesMutationMutation();

  const handleClose = () => {
    setOpen(false);
    setNotes('');
    setServiceCount(1);
    setSelectedDate(dayjs());
  };

  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs())
  const formattedDate = selectedDate?.format('YYYY-MM-DD HH:mm:ss');
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const onSubmitService = async (serviceId: string) => {
    try {
      let schedule_task = formattedDate; // Initialize with the formatted date
      if (selectedDate === null) {
        schedule_task = ''; // Set to an empty string if date is not selected
      }

      const res = await requestService({
        variables: {
          input: {
            quantity: serviceCount,
            notes: notes,
            property_id: guest?.property_id,
            room_id: guest?.room_id,
            task_id: parseInt(serviceId),
            schedule_task: schedule_task // Use the schedule_task variable
          }
        }
      });
      if (res.data?.requestService?.code === '200') {
        serviceAlert('Submitted Successfully');
        // taskHistoryRefetch();
      } else {
        serviceAlert(
          res.data?.requestService?.message ||
          'Something went wrong! Please contact support'
        );
      }
      handleClose();
    } catch (error) {
      sweetAlert.fire({
        text: 'Something went wrong',
        icon: 'error'
      });
    }
  };
  return {
    ServiceLoading,
    QuickServices: quickServices?.getQuickServices?.quicktasklist,
    loadingSubmitService,
    onSubmitService,
    tasks,
    setNotes,
    notes,
    open,
    setOpen,
    serviceCount,
    setServiceCount,
    selectedDate,
    handleDateChange,
    handleClose
  };
};

export default useQuickService;
