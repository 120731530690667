import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function NavHomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 26 25" {...props} width="24px" height="24px">
            <g clipPath="url(#clip0_15_1853)">
                <path d="M10.0004 19.0067C9.55856 19.0067 9.20039 19.3649 9.20039 19.8067C9.20039 20.2484 9.55856 20.6067 10.0004 20.6067H16.0004C16.4422 20.6067 
                16.8004 20.2484 16.8004 19.8067C16.8004 19.3649 16.4422 19.0067 16.0004 19.0067H10.0004Z"/>
                <path d="M23.5265 7.29772L23.4026 7.4547L23.5265 7.29772L15.7265 1.13983C14.1279 -0.122193 11.8721 -0.122196 10.2736 1.13983L2.47356 7.29772C1.41664 
                8.13214 0.8 9.40459 0.8 10.7512V20.4066C0.8 22.8367 2.76994 24.8066 5.2 24.8066H20.8C23.2301 24.8066 25.2 22.8367 25.2 20.4066V10.7512C25.2 9.40459 
                24.5834 8.13214 23.5265 7.29772ZM11.265 2.39564C12.2823 1.59253 13.7177 1.59253 14.735 2.39564L22.535 8.55353C23.2076 9.08453 23.6 9.89428 23.6 
                10.7512V20.4066C23.6 21.953 22.3464 23.2066 20.8 23.2066H5.2C3.65361 23.2066 2.4 21.953 2.4 20.4066V10.7512C2.4 9.89427 2.7924 9.08452 3.465 
                8.55353L11.265 2.39564Z"/>

            </g>
            <defs>
                <clipPath id="clip0_15_1853">
                    <rect width="27" height="34" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}

export { NavHomeIcon };
