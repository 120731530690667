import * as React from "react";
import { startCase } from "lodash";

export function useTitle(title: string) {
  React.useEffect(() => {
    if (!title) return;

    const prevTitle = document.title;
    document.title = startCase(title);
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
