import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function MainsIcon(props: SvgIconProps) {
  const theme = useTheme();
  //   fill={theme.palette.primary.main}
  return (
    <SvgIcon viewBox="0 0 40 28" {...props}>
      <g clipPath="url(#clip0_15_1857)">
        <path
          d="M37.2739 20.6603C37.0893 16.2957 35.3115 12.1537 32.2818 9.02925C29.252 5.90482 25.1839 4.01818 20.8582 3.73152V2.32687H22.8209V0H16.5865V2.32687H18.5492V3.73152C14.2235 4.01818 10.1554 5.90482 7.12562 9.02925C4.09589 12.1537 2.3181 16.2957 2.13354 20.6603H0V23.3075C0.00142594 24.5516 0.492482 25.7443 1.36544 26.624C2.23841 27.5037 3.42199 27.9986 4.65654 28H34.7509C35.9854 27.9986 37.169 27.5037 38.042 26.624C38.9149 25.7443 39.406 24.5516 39.4074 23.3075V20.6603H37.2739ZM19.7037 6.02039C27.8761 6.02039 34.5708 12.5209 34.9633 20.6603H4.44411C4.83664 12.5209 11.5313 6.02039 19.7037 6.02039ZM37.0984 23.3075C37.0978 23.9347 36.8502 24.5361 36.4101 24.9796C35.97 25.4231 35.3733 25.6725 34.7509 25.6731H4.65654C4.03413 25.6725 3.43738 25.4231 2.99727 24.9796C2.55716 24.5361 2.30964 23.9347 2.30903 23.3075V22.9871H37.0984V23.3075Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M6.59229 18.5265L8.87745 18.8616C8.88207 18.8321 9.33926 15.8948 11.2042 14.2404L9.67946 12.493C7.16338 14.7213 6.61461 18.3714 6.59229 18.5265Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1857">
          <rect width="40" height="28" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { MainsIcon };
