import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function SecurityIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 31 34" {...props}>
      <g clipPath="url(#clip0_446_607)">
        <path
          d="M19.0181 11.1841C19.0168 10.1429 18.6027 9.14474 17.8664 8.4085C17.1302 7.67226 16.132 7.2581 15.0908 7.25687C14.0499 7.25898 13.0523 7.67344 12.3164 8.40951C11.5804 9.14558 11.1661 10.1433 11.1642 11.1841V11.8867H9.12817V21.258H21.0534V11.8867H19.0181V11.1841ZM13.1557 11.1841C13.1578 10.6716 13.3624 10.1806 13.7248 9.81815C14.0872 9.45571 14.5782 9.25116 15.0908 9.24906C15.6038 9.24959 16.0957 9.45363 16.4585 9.81642C16.8213 10.1792 17.0253 10.6711 17.0259 11.1841V11.8867H13.1557V11.1841ZM19.0612 13.8789V19.2651H11.121V13.8789H19.0612Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M14.0947 15.543H16.0869V17.6016H14.0947V15.543Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M22.8398 0H7.34121L0 5.12723V16.0451C0 18.7471 1.23781 21.8835 3.48566 24.8771C6.10605 28.3648 9.96492 31.4427 14.6459 33.7789L15.0908 34L15.5357 33.7782C20.2167 31.442 24.0756 28.3641 26.696 24.8798C28.9438 21.8868 30.1816 18.7505 30.1816 16.0477V5.12723L22.8398 0ZM28.1895 16.0451C28.1895 20.4007 24.0869 27.1309 15.0908 31.7694C6.09477 27.1309 1.99219 20.4007 1.99219 16.0451V6.16648L7.96875 1.99219H22.2129L28.1895 6.16648V16.0451Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M8.59027 3.96976L3.96973 7.19711V16.0451C3.96973 16.8287 4.18023 17.8095 4.5634 18.8056C5.02824 20.0228 5.7368 21.2626 6.65984 22.4918C7.98398 24.2549 10.435 26.8573 14.5941 29.2486L15.0908 29.5342L15.5875 29.2486C19.7465 26.8573 22.1963 24.2542 23.5244 22.4918C24.4474 21.2626 25.1527 20.0228 25.6208 18.8056C26.004 17.8095 26.2145 16.8287 26.2145 16.0451V7.19711L21.5933 3.96976H8.59027ZM24.2196 16.0451C24.2196 16.5823 24.0516 17.3287 23.7588 18.0911C23.3604 19.1356 22.7414 20.2161 21.9286 21.2952C20.7698 22.8384 18.6555 25.0916 15.0888 27.2299C11.5241 25.0916 9.40973 22.8384 8.24895 21.2952C7.43613 20.2141 6.82055 19.1356 6.41879 18.0911C6.12594 17.328 5.95793 16.5823 5.95793 16.0451V8.23437L9.21184 5.96062H20.9638L24.2177 8.23437V16.0437L24.2196 16.0451Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_446_607">
          <rect width="30.1823" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { SecurityIcon };
