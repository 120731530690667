import {
  Box,
  Fade,
  Modal,
  Button,
  Backdrop,
  Typography,
  useMediaQuery
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

function ErrorPopup() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width:480px)');

  React.useEffect(() => {
    setTimeout(handleOpen, 500);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'primary.main',
        justifyContent: 'center',
        fontSize: theme => theme.spacing(2.5)
      }}
    >
      <Modal
        open={open}
        disableAutoFocus
        closeAfterTransition
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 1000 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              p: 3,
              top: '50%',
              left: '50%',
              textAlign: 'center',
              position: 'absolute',
              bgcolor: 'background.paper',
              transform: 'translate(-50%, -50%)',
              width: isMobileScreen ? '90vw' : 400,
              borderRadius: theme => theme.spacing(1.25)
            }}
          >
            <Typography variant="h6" component="h2">
              Sorry! you are not check in, please verify details?
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/verify-user')}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default ErrorPopup;
