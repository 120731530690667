import * as React from 'react';

export * from './hooks';

export const delay = (timeInMs: number) =>
  new Promise(resolve => setTimeout(resolve, timeInMs));

export const getLocalStorage = (key: string) =>
  JSON.parse(window.localStorage.getItem(key) as string);

export const setLocalStorage = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const getInitials = (str: string) => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const imageFallback: React.ReactEventHandler<HTMLImageElement> = ({
  currentTarget
}) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = '/assets/no-image-placeholder.png';
};

export const scrollToBottom = (ref: any) => {
  ref.scrollTop = ref.scrollHeight;
};
