import { Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../store';
// import { ChatIcon } from '../../../components';
import { imageFallback } from '../../../utils';
import useHotelInfo from '../../HotelInfo/hotel-info-hooks';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LOGO_FONT } from '../../../constants';
import { TalkToUS } from '../../../components/TalkToUs';
// import { TalkToUS } from '../../../components/TalkToUs';

export interface PropertyDetails {
  getPropertyByDomain?: {
    show_talk_to_us?: boolean;
  };
}

// interface TalkToUSProps {
//   propertyDetails?: PropertyDetails;
// }
const Header = () => {
  const guest = useAuth((state: any) => state.guest);
  // const navigate = useNavigate();
  const guestName = guest?.guest_name || '';
  const { propertyDetails } = useHotelInfo();
  // const FONT_FAMILY = {fontFamily: propertyDetails?.getPropertyByDomain?.font?propertyDetails?.getPropertyByDomain?.font:"Cormorant"};
  return (
    <Box
      sx={{
        px: 3,
        pt: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        '@media(max-width:280px)': {
          flexDirection: 'column'
        },
        fontSize: theme => theme.spacing(2.5)
      }}
    >
      <Box
        sx={{
          ...LOGO_FONT
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box> {propertyDetails?.getPropertyByDomain?.palm_text}</Box>
          {/* {guestName} */}
          {propertyDetails?.getPropertyByDomain?.palm_icon && (
            <Box
              width={36}
              height={36}
              component="img"
              alt="Hello Emoji"
              onError={imageFallback}
              src="/assets/hand-emoji.png"
              style={{ verticalAlign: 'bottom' }}
              sx={{
                mt: '-8px'
              }}
            />
          )}
        </Box>
        <Box>{guestName}</Box>
      </Box>
      {propertyDetails?.getPropertyByDomain?.show_talk_to_us &&
        guest?.guest_id && (
          <TalkToUS
            chat_text={propertyDetails?.getPropertyByDomain?.chat_text}
          />
        )}
      {/* <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
      > */}
      {/* {propertyDetails?.getPropertyByDomain?.whatsapp_icon && (
          <IconButton
            aria-label="Linkedin.com"
            onClick={() =>
              window.open(
                `https://wa.me/${propertyDetails?.getPropertyByDomain?.restaurant_phone}?text=Guest_Name:=${guest?.guest_name}%0ARoom_Number:=${guest?.room}`
              )
            }
          >
            <WhatsAppIcon fontSize="large" />
          </IconButton>
        )} */}
      {/* {propertyDetails?.getPropertyByDomain?.show_talk_to_us && (
          <Chip
            variant="filled"
            label="Let's Chat"
            onClick={() => navigate('/chat')}
            sx={{
              backgroundColor: '#B2A279',
              '& .MuiChip-deleteIcon': {
                color: 'white',
              },
              '&:hover': {
                backgroundColor: '#A08F68',
              },
              fontSize: theme => theme.spacing(1.75),
              px: 1,
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                right: '-5px',
                transform: 'translateY(-50%)',
                width: 0,
                height: 0,
                borderLeft: '10px solid #B2A279',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
              },
            }}
          />
        )}
        <Box onClick={()=>navigate('/chat')} sx={{ml:"5px !important", mt:"5px !important"}}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.3333 10.6667H26.6667C27.4031 10.6667 28 11.2636 28 12V26.6667L23.556 22.9747C23.3167 22.7757 23.0144 22.6667 22.7031 22.6667H12C11.2636 22.6667 10.6667 22.0697 10.6667 21.3333V17.3333M21.3333 10.6667V6.66668C21.3333 5.9303 20.7364 5.33334 20 5.33334H5.33333C4.59696 5.33334 4 5.9303 4 6.66668V21.3337L8.44401 17.6411C8.6834 17.4423 8.98565 17.3333 9.29688 17.3333H10.6667M21.3333 10.6667V16C21.3333 16.7364 20.7364 17.3333 20 17.3333H10.6667" stroke="#F2F2F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Box>
      </Stack> */}
    </Box>
  );
};

export { Header };
